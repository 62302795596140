import { calculateInstallments } from "../../utils/calculateInstallments";
 import { unidadeRetirada } from "../../utils/affiliatesInfo";
 import { Link } from "react-router-dom";
 import { CategoryContext } from "../../contexts/CategoryContext";
 import { useContext } from "react";
 import { ProductsContext } from "../../contexts/ProductsContext";
 import { percentage } from "../../utils/percentage";
 
 const ItemDisplay = () => {
     const { categoryIcons } = useContext(CategoryContext);
     const { selectedSubproduct, selectedProduct } = useContext(ProductsContext);
     const { NOME, PRECO, TIPO, DESCONTO, CODCOLIGADA, parcelas, paymentPlan } =
           selectedSubproduct;
         const title = NOME ? NOME.toUpperCase() : "";
         const discountAmount = PRECO - DESCONTO;
         const percentageDiscount = percentage(discountAmount, PRECO);
 
   return (
     <div className="item-info-container">
       <div className="item-title-container">
         <h5>Informações do Produto</h5>
       </div>
       <div className="item-content-container">
         <img
           src={selectedProduct.image}
           alt="imagem-produto"
           className="item-product-image"
         />
         <div className="item-description">
           <div>
             <Link
               to={`/produto/${selectedProduct.category.slug}/${selectedProduct.slug}`}
             >
               <h4 className="font-semibold">{title}</h4>
             </Link>
             <h5 className="font-semibold">
               Unidade - {unidadeRetirada(CODCOLIGADA)}
             </h5>
             <Link to={`/categoria/${selectedProduct.category.slug}`}>
               <p className="item-category">
                 <span className="material-symbols-outlined">
                   {categoryIcons[selectedProduct.category.slug] ??
                     categoryIcons["outros"]}
                 </span>
 
                 {selectedProduct.category.title}
               </p>
             </Link>
           </div>
           <div className="item-price">
             {PRECO === 0 ? (
               <h4>
                 Este produto/serviço é{" "}
                 <span className="price-green">grátis</span>
               </h4>
             ) : (
               <>
                 {PRECO !== DESCONTO && (
                   <h3>
                     de {/* ou{' '} */}
                     <span className="price-blue">
                       R${" "}
                       {!PRECO || PRECO === null || PRECO === undefined
                         ? null
                         : `${calculateInstallments(
                             paymentPlan,
                             TIPO,
                             parcelas
                           )}x${(
                             PRECO /
                             calculateInstallments(paymentPlan, TIPO, parcelas)
                           )?.toFixed(2)}`}
                     </span>{" "}
                     {/* parcelado */}
                   </h3>
                 )}
                 <h4>
                   Por{" "}
                   <span className="price-green">
                     R${" "}
                     {!DESCONTO || DESCONTO === null || DESCONTO === undefined
                       ? null
                       : `${calculateInstallments(
                           paymentPlan,
                           TIPO,
                           parcelas
                         )}x${(
                           DESCONTO /
                           calculateInstallments(paymentPlan, TIPO, parcelas)
                         )?.toFixed(2)}`}
                     {DESCONTO === 0 && DESCONTO?.toFixed(2)}
                   </span>{" "}
                   {/* à vista */}
                   {percentageDiscount < 1 ||
                   percentageDiscount === "NaN" ||
                   percentageDiscount === undefined ? null : (
                     <span className="porcentagem-desconto">
                       ({percentageDiscount}% desconto)
                     </span>
                   )}
                 </h4>
               </>
             )}
           </div>
         </div>
       </div>
     </div>
   );
 };
 
 export default ItemDisplay;