import { useContext, useState } from "react";
import IconPlus from "../../../assets/Icons/icon-plus.svg";
import IconMinus from "../../../assets/Icons/icon-minus.svg";
import AnimateHeight from "react-animate-height";
import { capitalizeString } from "../../../utils/capitalizeString";
import { AccountContext } from "../../../contexts/AccountContext";
import BagItem from "./BagItem/BagItem";

const BagView = ({ index, bag }) => {
  const { studentList } = useContext(AccountContext);
  const [toggle, setToggle] = useState(!index ? true : false);
  const [isRotating, setIsRotating] = useState(false);
  const getStudentFromList = studentList.find(
    (student) => student.studentId === bag.studentId
  );
  console.log(bag);
  const RA = getStudentFromList.student.RA;

  const handleToggle = () => {
    setIsRotating(true);
    setTimeout(() => {
      setToggle((prev) => !prev);
      setIsRotating(false);
    }, 150);
  };

  return (
    <div className="shadow-md rounded-lg">
      <div
        className={`flex justify-between items-center bg-blue-dark text-white transition-all duration-300 ${
          toggle ? "rounded-t-lg" : "rounded-lg"
        } p-2 px-3`}
      >
        <div className="flex items-center gap-1">
          <span class="material-symbols-outlined text-green-base text-5xl">
            shopping_cart
          </span>
          <div className="flex flex-col">
            <h1 className="text-lg font-semibold">
              {capitalizeString(bag.name, 2)}
            </h1>
            {!!RA && (
              <div className="flex items-center gap-1">
                <label className="text-grey-base font-medium">RA</label>
                <span className="text-grey-medium">{RA}</span>
              </div>
            )}
          </div>
        </div>
        <button onClick={handleToggle}>
          <img
            className={`transition-transform duration-300 ${
              isRotating ? "rotate-180" : ""
            }`}
            src={toggle ? IconMinus : IconPlus}
            alt=""
          />
        </button>
      </div>

      <AnimateHeight height={toggle ? "auto" : 0} duration={300}>
        <div className="flex flex-col gap-3 bg-white p-3 rounded-b-md shadow-md">
          {bag.cartItems.map((item) => (
            <BagItem item={item} key={item.itemTOTVSId} bag={bag} />
          ))}
        </div>
      </AnimateHeight>
    </div>
  );
};

export default BagView;
