import React, { useContext, useState } from "react";

import { AccountContext } from "../../../../contexts/AccountContext";

import CardVinculo from "../../../CardVinculo/CardVinculo";
// import CardNovoVinculo from "../../../CardVinculoNovo/CardNovoVinculo";
import NovaVinculação from "../NovaVinculação/NovaVinculação";
import BtnBase from "../../../Buttons/BtnBase/BtnBase";
import ServiceMessageVinculo from "../../../../components/ServiceMessage/ServiceMessageVinculo";

import LoadingBars from "../../../../assets/Loading/loading-bars.svg";
import "../ModalVinculação.styles.scss";

const VinculaçãoContainer = ({
  addItemToCartHandler,
  selectedStudent,
  selectedSubproduct,
  setOpenList
}) => {
  const {
    handleChangeAddStudent,
    handleSubmitAddStudent,
    studentList,
    removeStudentFromUser,
    isLoading,
  } = useContext(AccountContext);
  const [openTab, setOpenTab] = useState(1);

  const Student = selectedStudent;
  const Product = selectedSubproduct;

  return (
    <>
      <div className="modal-vinculo-tabnav">
        <div className="tabnav-fade-left" />
        <div className="tabnav-bar">
          <a
            className={
              "list-anchor " +
              (openTab === 1 ? "list-anchor-active" : "list-anchor-inactive")
            }
            onClick={(e) => {
              e.preventDefault();
              setOpenTab(1);
            }}
            data-toggle="tab"
            href="#link1"
            role="tablist"
          >
            <div className="tabnav-icon">
              <span className="material-symbols-outlined">
                <span className="material-symbols-outlined">checklist_rtl</span>
              </span>
            </div>
            <h2>Estudantes Vinculados</h2>
          </a>
          {/* <a
            className={
              "list-anchor " +
              (openTab === 2 ? "list-anchor-active" : "list-anchor-inactive")
            }
            onClick={(e) => {
              e.preventDefault();
              setOpenTab(2);
            }}
            data-toggle="tab"
            href="#link2"
            role="tablist"
          >
            <div className="tabnav-icon">
              <span className="material-symbols-outlined">
                <span className="clip material-symbols-outlined">add_link</span>
              </span>
            </div>
            <h2>Nova Vinculação</h2>
          </a> */}
        </div>
        <div className="tabnav-fade-right" />
      </div>
      <div className="modal-vinculo-content">
        {openTab === 1 && (
          <div className="modal-vinculo-content-tab1">
            <div className="modal-vinculo-tab1-search">
              <div className="search-box">
                <form className="search-form" onSubmit={handleSubmitAddStudent}>
                  <div className="relative">
                    <div className="search-icon">
                      <span className="material-symbols-outlined">
                        person_search
                      </span>
                    </div>

                    <div
                      id="example-panel"
                      animateOpacity={true}
                      duration={500}
                      height={100}
                    >
                      <ServiceMessageVinculo />
                    </div>

                    <input
                      type="search"
                      id="search-ra"
                      className="search-field"
                      placeholder="Adicionar estudante por RA"
                      required
                      onChange={({ target: { value } }) =>
                        handleChangeAddStudent(value)
                      }
                    />
                    <button
                      disabled={isLoading === true ? true : false}
                      type="submit"
                      className={
                        isLoading === true
                          ? `btn-pesquisar-loading`
                          : `btn-pesquisar`
                      }
                    >
                      {isLoading === true ? (
                        <img src={LoadingBars} alt="" />
                      ) : (
                        "Adicionar"
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div id="link1" className="modal-vinculo-tab1-lista">
              {isLoading === true ? (
                <img className="big-loading-bars" src={LoadingBars} alt="" />
              ) : (
                <div className="vinculo-lista-item">
                  {studentList.map((student) => (
                    <CardVinculo
                      key={student.id}
                      Student={student}
                      Product={selectedSubproduct}
                      removeStudentFromUser={() =>
                        removeStudentFromUser(student.student.id)
                      }
                    />
                  ))}
                  {/* <CardNovoVinculo setOpenTab={setOpenTab} /> */}
                </div>
              )}
            </div>
            <div className="modal-vinculo-tab1-btns">
                <BtnBase
                  OnClick={() => setOpenList(false)}
                  BtnTxt={"Voltar"}
                  BtnClass={"btn-grey-sm"}
                />
              {/* <BtnBase
										OnClick={addProductHandler}
										BtnTxt={'Confirmar'}
										BtnClass={
											isNotMobileDevice ? 'btn-yellow-sm' : 'btn-yellow-lg'
										}
									/> */}
              <BtnBase
                Disabled={!selectedStudent ? true : false}
                OnClick={() => addItemToCartHandler(Student, Product)}
                BtnTxt={"Confirmar"}
                BtnClass={"btn-yellow-sm"}
              />
            </div>
          </div>
        )}

        {openTab === 2 && (
          <div id="link2" className="modal-vinculo-content-tab2">
            <NovaVinculação setOpenTab={setOpenTab} />
          </div>
        )}
      </div>
    </>
  );
};

export default VinculaçãoContainer;
