import React from "react";
import "./BtnBase.styles.scss";

const BtnBase = ({ BtnTxt, BtnClass, OnClick, Type, Disabled, className }) => {
  return (
    <div className="button-sm">
      <button
        disabled={Disabled}
        onClick={OnClick}
        type={!Type ? "Button" : Type}
        className={`${
          Disabled === true && BtnClass === "btn-yellow-sm"
            ? "btn-sm-disabled"
            : Disabled === true && BtnClass === "btn-yellow-lg"
            ? "btn-lg-disabled"
            : BtnClass
        } ${className}`}
      >
        {BtnTxt}
        {BtnClass === "btn-cart" ? (
          <span className="material-symbols-outlined">arrow_right_alt</span>
        ) : null}
      </button>
    </div>
  );
};

export default BtnBase;
