import React, { lazy, Suspense } from 'react';
import './App.scss';
import { Routes, Route, Outlet, Navigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { useContext } from 'react';
import { AccountContext } from './contexts/AccountContext';
import Spinner from './components/Spinner/Spinner';

// PRESISTENT LAYOUT COMPONENTS
import Footer from './components/Footer/Footer';
import Navbar from './components/Navbar/Navbar';
import NavbarMobile from './components/NavbarMobile/NavbarMobile';
import CompraPage from './pages/Compra/CompraPage';

// LAZY LOADING FOR BETTER PERFORMANCE
const HomePage = lazy(() => import('./pages/Home/HomePage'));
const CategoriaPage = lazy(() => import('./pages/Categoria/CategoriaPage'));
const ProdutoPage = lazy(() => import('./pages/Produto/ProdutoPage'));
const ContaPage = lazy(() => import('./pages/Conta/ContaPage'));
const CheckoutPage = lazy(() => import('./pages/Checkout/CheckoutPage'));
const ConclusaoPage = lazy(() => import('./pages/Conclusao/ConclusaoPage'));
const AtendimentoPage = lazy(() =>
	import('./pages/Atendimento/AtendimentoPage')
);
const CompraRapida = lazy(() => import('./pages/CompraRapida/CompraRapida'));
const BuscaPage = lazy(() => import('./pages/Busca/BuscaPage'));
const FaqPage = lazy(() => import('./pages/FAQ/FaqPage'));
const ResetPage = lazy(() => import('./pages/ResetPassword/ResetPage'));
const NoMatchPage = lazy(() => import('./pages/NoMatch/NoMatchPage'));
const ComprovantePublico = lazy(() =>
	import('./components/Comprovante/ComprovantePublico')
);

// PERSISTENT LAYOUT HANDLER
const PersistentLayout = () => {
	const isMobileDevice = useMediaQuery({
		query: '(min-device-width: 710px)',
	});
	return (
		<>
			{isMobileDevice ? <Navbar /> : <NavbarMobile />}
			<Outlet />
			<Footer />
		</>
	);
};

const App = () => {
	const { loggedUserData } = useContext(AccountContext);

	return (
		<Suspense fallback={<Spinner />}>
			<Routes>
				<Route path="/" element={<PersistentLayout />}>
					<Route index element={<HomePage />} />
					<Route path="/categoria/:categorySlug" element={<CategoriaPage />} />
					<Route
						path="/produto/:categorySlug/:productSlug"
						exact
						element={<ProdutoPage />}
					/>
					<Route
						path="/conta"
						element={
							!loggedUserData ? <Navigate replace to="/" /> : <ContaPage />
						}
					/>
					<Route
 					    path="/compra"
 						element={<CompraPage />}
 					/>
					{/* <Route
						path="/checkout"
						element={
							!loggedUserData ? <Navigate replace to="/" /> : <CheckoutPage />
						}
					/> */}
					<Route
						path="/conclusao"
						element={
							!loggedUserData ? <Navigate replace to="/" /> : <ConclusaoPage />
						}
					/>
					<Route path="/atendimento" element={<AtendimentoPage />} />
					<Route path="/compra-rapida" element={<CompraRapida />} />
					<Route path="/busca" element={<BuscaPage />} />
					<Route path="/faq" element={<FaqPage />} />
					<Route path="/resetar-senha" element={<ResetPage />}>
						<Route path=":token" element={<ResetPage />} />
					</Route>
					<Route path="*" element={<NoMatchPage />} />
				</Route>
				<Route
					path="/comprovantePublico/:saleBagId/:saleId"
					element={<ComprovantePublico />}
				/>
			</Routes>
		</Suspense>
	);
};

export default App;
