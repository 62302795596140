import React, { useContext } from 'react';
import '../RegistrarForm.styles.scss';
import { AccountContext } from '../../../../contexts/AccountContext';
import { useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';
import axios from 'axios';
import BtnBase from '../../../Buttons/BtnBase/BtnBase';
import { isValidCPF } from '../../../../utils/isValidCPF';

const RegistrarAdicionais = ({ nextStep, prevStep, currentStep, setCurrentStep }) => {
	const formName = 'register';
	const { account, setAccount, signUp, Toast } = useContext(AccountContext);
	const { register, setValue, setFocus, handleSubmit } = useForm({
		defaultValues: { ...account },
	});



	// FETCH CEP
	const checkCEP = (e) => {
		if (!e.target.value) return;
		const cep = e.target.value.replace(/\D/g, '');
		axios
			.get(`https://viacep.com.br/ws/${cep}/json/`)
			.then((response) => {
				setValue(`${formName}.state`, response.data.uf);
				setValue(`${formName}.city`, response.data.localidade);
				setValue(`${formName}.neighborhood`, response.data.bairro);
				setValue(`${formName}.street`, response.data.logradouro);
				setFocus(`${formName}.houseNumber`);
			})
			.catch((err) => console.log(err));
	};

	// SUBMIT FORM - STEP 2
	const onSubmit = (formData) => {
		let {
			[formName]: { cpf, cellPhone },
		} = formData;
		const cpfString = cpf.replace(/\D/g, '');
		const telString = cellPhone.replace(/\D/g, '');
		if (cpfString === undefined || !isValidCPF(cpfString)) {
			Toast.fire({
				icon: 'error',
				iconColor: '#fff',
				color: '#fff',
				background: '#ff6363',
				title: 'CPF Inválido',
			});
			setFocus(`${formName}.cpf`);
		} else if (telString === undefined || telString.length < 11) {
			Toast.fire({
				icon: 'error',
				iconColor: '#fff',
				color: '#fff',
				background: '#ff6363',
				title: 'Telefone Incompleto',
			});
			setFocus(`${formName}.cellPhone`);
		} else {
			setAccount(formData);
			signUp(formData, nextStep, currentStep, setCurrentStep);
			// nextStep();
		}
	};

	return (
		<>
			<form className="form-complementary" onSubmit={handleSubmit(onSubmit)}>
				<div className="form-complementary-title">
					<div className="form-complementary-title-top">
						Passo 2/2 - Informações Básicas
					</div>
					<div className="form-complementary-title-link">
						Informações básicas de identificação do usuário.
					</div>
				</div>
				<div className="form-complementary-content">
					<div className='form-complementary-primary-info'>
						<div className="form-container-cpf">
							<label htmlFor={formName + 'cpf'} className="form-label">
								CPF
							</label>
							<div className="form-field">
								<InputMask
									// mask options
									mask="999.999.999-99"
									alwaysShowMask={false}
									// input options
									type="text"
									placeholder="999.999.999-99"
									minLength="11"
									// react hook form register
									{...register(`${formName}.cpf`, {
										required: true,
									})}
								/>
							</div>
						</div>

						<div className="form-container-nascimento">
							<label htmlFor={formName + 'birthDate'} className="form-label">
								Data de Nascimento
							</label>
							<div className="form-field">
								<input
									{...register(`${formName}.birthDate`, { required: true })}
									placeholder="Data de Nascimento"
									type="date"
									max={new Date().toISOString().split("T")[0]}
									maxLength="8"
									pattern="[0-9]+"
								/>
							</div>
						</div>
					</div>
					<div className="form-complementary-secondary-info">
						<div className="form-container-telefone">
							<label htmlFor={formName + 'cellPhone'} className="form-label">
								Celular
							</label>
							<div className="form-field">
								<InputMask
									// mask options
									mask="(99) 99999-9999"
									alwaysShowMask={false}
									// input options
									type={'tel'}
									minLength="8"
									placeholder="(86) 99999-9999"
									// react hook form register
									{...register(`${formName}.cellPhone`, {
										required: true,
									})}
								/>
							</div>
						</div>
						<div className="form-container-cep">
							<label htmlFor={formName + 'zipcode'} className="form-label">
								CEP
							</label>
							<div className="form-field">
								<input
									{...register(`${formName}.zipcode`, {
										required: true,
									})}
									placeholder="64048152"
									onBlur={checkCEP}
									// onInput={(e) => (e.target.value = e.target.value.slice(0, 8))}
									type="text"
									minLength="8"
									maxLength="8"
									pattern="[0-9]+"
								/>
								<button type="button" onClick={checkCEP}>
									<span className="material-symbols-outlined">
										travel_explore
									</span>
								</button>
							</div>
						</div>

						<div className="form-container-estado">
							<label htmlFor={formName + 'state'} className="form-label">
								Estado
							</label>
							<div className="form-field">
								<input
									{...register(`${formName}.state`, { required: true })}
									placeholder="Estado"
									type="text"
									disabled
								/>
							</div>
						</div>
					</div>
					<div className="form-complementary-tertiary-info">
						<div className="form-container-cidade">
							<label htmlFor={formName + 'city'} className="form-label">
								Cidade
							</label>
							<div className="form-field">
								<input
									{...register(`${formName}.city`, { required: true })}
									placeholder="Cidade"
									type="text"
									disabled
								/>
							</div>
						</div>

						<div className="form-container-bairro">
							<label htmlFor={formName + 'neighborhood'} className="form-label">
								Bairro
							</label>
							<div className="form-field">
								<input
									{...register(`${formName}.neighborhood`, { required: true })}
									placeholder="Bairro"
									type="text"
								// disabled
								/>
							</div>
						</div>
						<div className="form-container-número">
							<label htmlFor={formName + 'houseNumber'} className="form-label">
								Número
							</label>
							<div className="form-field">
								<input
									{...register(`${formName}.houseNumber`, {
										required: true,
									})}
									placeholder="999"
									type="text"
									minLength="2"
									maxLength="6"
									pattern="[0-9]+"
								/>
							</div>
						</div>
					</div>
					<div className='form-rua-info'>
						<div className="form-container-rua">
							<label htmlFor={formName + 'street'} className="form-label">
								Rua
							</label>
							<div className="form-field">
								<input
									{...register(`${formName}.street`, { required: true })}
									placeholder="Rua"
									type="text"
								// disabled
								/>
							</div>
						</div>
					</div>
				</div>
				<div className="form-btns-container">
					<BtnBase
						OnClick={prevStep}
						BtnTxt={'Voltar'}
						BtnClass={'btn-grey-sm'}
					/>
					<BtnBase
						Type={'submit'}
						BtnTxt={'Criar Conta'}
						BtnClass={'btn-blue-sm'}
					/>
				</div>
			</form>
		</>
	);
};

export default RegistrarAdicionais;
