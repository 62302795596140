import { useContext } from "react";
import { CategoryContext } from "../../../../contexts/CategoryContext";
import { CartContext } from "../../../../contexts/CartContext";
import { Link } from "react-router-dom";
import { capitalizeString } from "../../../../utils/capitalizeString";
import { unidadeRetirada } from "../../../../utils/affiliatesInfo";
import { percentage } from "../../../../utils/percentage";
import formatPriceBR from "../../../../utils/formatPriceBR";
import IconChevronUp from "../../../../assets/Icons/icon-chevron.svg";
import IconChevronDown from "../../../../assets/Icons/icon-chevron-down.svg";
import BtnRemove from "../../../Buttons/BtnRemove/BtnRemove";

const BagItem = ({ item, bag }) => {
  const { categoryIcons } = useContext(CategoryContext);
  const { removerVinculacao, montarCarrinho } = useContext(CartContext);

  const Student = bag;

  const Product = {
    id: item.itemTOTVSId,
    affiliate: { id: item.affiliateId },
  };

  let Quantity = item.quantity;

  const discountAmount = item.PRECO - item.DESCONTO;
  const percentageDiscount = percentage(discountAmount, item.PRECO);

  function increment() {
    Quantity += 1;
    return Quantity;
  }

  function decrement() {
    if (Quantity > 1) {
      return (Quantity -= 1);
    } else {
      return (Quantity = 1);
    }
  }

  function handleIncrement() {
    increment();
    montarCarrinho(true, Student, Product, Quantity);
  }

  function handleDecrement() {
    decrement();
    montarCarrinho(false, Student, Product, Quantity);
  }

  function handleRemoverItem() {
    removerVinculacao(false, Student, Product);
  }

  return (
    <div className="flex gap-2 p-2 rounded-md custom-shadow-sm">
      <img
        className="w-[20%] h-fit self-start rounded-md"
        src={item.productImage}
        alt=""
      />
      <div className="flex flex-col gap-1 justify-between w-full">
        <div className="flex justify-between w-full">
          <div className="flex flex-col justify-start">
            <Link to={`/produto/${item.category.slug}/${item.productSlug}`}>
              <h3 className="text-sm sm:text-base font-semibold text-grey-dark">
                {capitalizeString(item.NOME)}
              </h3>
            </Link>
            <h5 className="font-semibold text-xs sm:text-sm text-grey-base">
              Unidade - {unidadeRetirada(item.CODCOLIGADA)}
            </h5>
            <Link to={`/categoria/${item.category.slug}`}>
              <h6 className="flex gap-1 items-center text-blue-brand text-xs sm:text-sm font-semibold">
                <span className="material-symbols-outlined text-base sm:text-lg">
                  {categoryIcons[item.category.slug] ?? categoryIcons["outros"]}
                </span>

                {item.category.title}
              </h6>
            </Link>
          </div>
          <BtnRemove
            className="self-start !p-0 !m-0 flex justify-center"
            OnClick={handleRemoverItem}
          />
        </div>
        <div className="flex justify-between items-center">
          {item.TIPO !== "S" && (
            <div className="flex items-center gap-1 text-grey-dark font-semibold">
              Qtd.
              <div className="flex gap-1 items-center">
                <div id="decrement-count">
                  <input
                    type="image"
                    className="-rotate-90"
                    id={Quantity === 1 ? `disabled-arrow` : `up-arrow`}
                    disabled={Quantity === 1 ? true : false}
                    src={IconChevronUp}
                    alt="minus"
                    onClick={() => handleDecrement()}
                  />
                </div>
                {item.quantity}
                <div id="increment-count">
                  <input
                    type="image"
                    className="-rotate-90"
                    disabled={
                      item.TIPO === "S" || item.TIPO === "M" ? true : false
                    }
                    src={IconChevronDown}
                    alt="plus"
                    onClick={() => handleIncrement()}
                  />
                </div>
              </div>
            </div>
          )}
          {item.PRECO === 0 ? (
            <span className="cartItem-price-txt w-full text-right">
              <h4 className="text-grey-dark">
                Por{" "}
                <span className="text-green-base font-semibold">R$ 0,00</span>
              </h4>
            </span>
          ) : (
            <span className="cartItem-price-txt w-full text-right">
              <h4 className="text-grey-dark text-xs sm:text-base">
                Por{" "}
                <span className="text-green-base font-semibold">
                  R$ {`${formatPriceBR(item.DESCONTO.toFixed(2) * Quantity)}`}
                </span>{" "}
                {percentageDiscount < 1 ? null : (
                  <span
                    className="porcentagem-desconto text-green-base font-semibold"
                    style={{ fontSize: "12px" }}
                  >
                    (-{percentageDiscount}%)
                  </span>
                )}
              </h4>
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default BagItem;
