import React from 'react';
import './BtnRemove.styles.scss';

const BtnRemove = ({ OnClick, className = "" }) => {
	return (
		<button onClick={OnClick} type="button" className={`btn-red ${className}`}>
			<span className="material-symbols-outlined">delete_forever</span>
		</button>
	);
};

export default BtnRemove;
