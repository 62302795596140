import React, { useContext } from 'react';
import { AccountContext } from '../../contexts/AccountContext';
import { CartContext } from '../../contexts/CartContext';
import './CardVinculo.styles.scss';
import { useLocation } from 'react-router-dom';
import { VinculoContext } from '../../contexts/VinculoContext';
import { GlobalContext } from '../../contexts/GlobalContext';
import { capitalizeString } from '../../utils/capitalizeString';

const CardVinculo = ({ Student, Product, onClickVinculate, vinculateCallback }) => {
	const { removeStudentFromUser } = useContext(AccountContext);
	const { setSelectedStudent, selectedStudent, addItemToCartHandler } = useContext(CartContext);
	const { toggleVinculoModal } = useContext(VinculoContext);
	const { setAffiliateId } = useContext(GlobalContext);

	const { RA, nome, schoolYears, id, Affiliates } = Student.student;

	let truncatedNome = capitalizeString(nome.toLowerCase(), 25);

	const studentId = id;
	const studentBodyId = Student.id;
	const activeSchoolYear = schoolYears.filter(sh => sh.deletedAt === null);
	const periodoLetivo = activeSchoolYear.length ? activeSchoolYear[0]?.CODPERLET : '';

	const filteredColigada = activeSchoolYear.length? Affiliates.filter(
		(affiliate) => affiliate.CODCOLIGADA === activeSchoolYear[0]?.CODCOLIGADA
	) : Affiliates.filter(
		(affiliate) => affiliate.CODCOLIGADA === schoolYears[0]?.CODCOLIGADA
	);

	const unidadeEscola = filteredColigada[0]?.name.toLowerCase();

	const location = useLocation();

	const vincHandler = () => {
		setAffiliateId(Product?.affiliate.id);
		setSelectedStudent(Student);

		if (!!onClickVinculate)
			addItemToCartHandler(Student, Product, undefined, vinculateCallback);
	};

	return (
		<>
			<div
				className={`${
					location.pathname === '/conta'
						? 'card-vinculo-container-conta'
						: 'card-vinculo-container'
				} + ${
					location.pathname !== '/conta' && Student.id === selectedStudent?.id
						? 'card-vinculo-selected'
						: null
				}`}>
				<div className="card-vinculo-content-left" onClick={vincHandler}>
					{/* <span class="iconVinc material-symbols-outlined">link</span> */}
					<div className="card-vinculo-nome">{truncatedNome}</div>
					<div className="card-vinculo-info">
						<div className="card-vinculo-ra">
							<span className="card-vinculo-info-bold">RA: </span>
							{RA}
						</div>
						<div className="card-vinculo-unidade">
							<span className="card-vinculo-info-bold">Unidade: </span>
							{unidadeEscola}
						</div>
						<div className="card-vinculo-periodo">
							<span className="card-vinculo-info-bold">Período Letivo: </span>
							{periodoLetivo}
						</div>
					</div>
				</div>
				<div className="card-vinculo-content-right">
					<button
						onClick={() => removeStudentFromUser(studentId, studentBodyId)}>
						<span className="close-btn material-symbols-outlined">close</span>
					</button>
					<button onClick={() => toggleVinculoModal(Student)}>
						<span className="edit-btn material-symbols-outlined">help</span>
					</button>
				</div>
			</div>
		</>
	);
};

export default CardVinculo;
