import React, { useState } from 'react';
import "./ServiceMessage.style.scss";


const ServiceMessageVinculo = () => {
    const [onHover, setOnHover] = useState(false);

    return (
        <div className="produto-descrição-aviso" onClick={() => setOnHover(!onHover)}>
            <div className={onHover ? "descrição-aviso-l" : "descrição-aviso-l-sm"}>
                <span className="material-symbols-outlined">error</span>
            </div>
            {onHover ? (
                <div className="descrição-aviso-r">
                    <div className="aviso-top">
                        <strong>1. Para alunos que não são da Great International School:</strong><br />
                        - Clique no card <u>"Criar Vinculação"</u>.<br />
                        - Preencha os dados necessários para vincular o aluno ao item desejado no carrinho.
                        <br />
                        <br />
                        <strong>2. Se o passo anterior já foi realizado:</strong><br />
                        - Clique no card do aluno correspondente, e ele será vinculado automaticamente ao item.
                    </div>
                </div>

            ) : <div className="descrição-aviso-r"><div className="aviso-top-sm">Aviso: adicionar estudante por RA se for aluno da Great International School, senão clique aqui.</div></div>}
        </div>
    )
}

export default ServiceMessageVinculo;