import "./BandeirasCard.styles.css"

const BandeirasCard = () => {
    return <div>
        <h3 className='modalidade-title'> Bandeiras Aceitas</h3>
		<div className="images-container">
		<img src='/visa.svg' alt='visa-card' />
		<img src='/mastercard.svg' alt='mastercard-card' />
		<img src='/hipercard.svg' alt='hipercard-card' />
		<img src='/elo.svg' alt='elo-card' />
		<img src='/america.svg' alt='america-express-card' />
		</div>
    </div>
}

export default BandeirasCard;