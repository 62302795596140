function isRomanNumeral(str) {
  const romanRegex =
    /^(M{0,3})(CM|CD|D?C{0,3})(XC|XL|L?X{0,3})(IX|IV|V?I{0,3})$/i;
  return romanRegex.test(str);
}

export function capitalizeString(sentence, maxWords) {
  const prepositions = [
    "a",
    "ante",
    "após",
    "até",
    "com",
    "contra",
    "de",
    "desde",
    "em",
    "entre",
    "para",
    "perante",
    "por",
    "sem",
    "sob",
    "sobre",
    "trás",
    "ao",
    "aos",
    "à",
    "às",
    "do",
    "dos",
    "da",
    "das",
    "no",
    "nos",
    "na",
    "nas",
    "pelo",
    "pelos",
    "pela",
    "pelas",
  ];

  const words = !!maxWords
    ? sentence.split(" ").slice(0, maxWords)
    : sentence.split(" ");
  let newSentence = "";

  for (const word of words) {
    if (newSentence.length > 0) newSentence += " ";

    if (isRomanNumeral(word)) newSentence += word.toUpperCase();
    else if (!prepositions.includes(word.toLowerCase()))
      newSentence += word[0].toUpperCase() + word.substring(1).toLowerCase();
    else newSentence += word.toLowerCase();
  }

  return newSentence;
}
