import React, { useContext } from 'react';
import Cards from 'react-credit-cards';
import InputMask from 'react-input-mask';
import { CartContext } from '../../contexts/CartContext';
import { PaymentContext } from '../../contexts/PaymentContext';
import BandeirasCard from '../BandeirasCard';
import './FormCartão.styles.scss';

const CreditCard = () => {
	const { payment, setPayment, parcelamentoArray } = useContext(PaymentContext);
	const { cartTotal, cartTotalParcelado } = useContext(CartContext);

	const handleChange = (e) => {
		const { name, value } = e.target;
		setPayment({ ...payment, [name]: value });
	};

	function handleInputFocus(e) {
		setPayment({ ...payment, focus: e.target.name });
	}

	return (
		<div>
			<BandeirasCard />
			<div id="PaymentForm">
				<Cards
					className="rccs__card rccs__card--unknown"
					number={payment?.cardNumber}
					name={payment?.holder}
					expiry={payment?.expirationDate}
					cvc={payment?.cvc}
					focused={payment?.focus}
					placeholders={{ name: 'TITULAR DO CARTÃO' }}
					locale={{ valid: 'Exp. Date' }}
				/>
				<form className="form-cartão-wrapper">
					{/* holder */}
					<div className="form-container-titular">
						<div className="form-label">Titular do Cartão</div>
						<div className="form-field">
							<input
								type="text"
								name="holder"
								placeholder="Nome Titular do Cartão"
								maxLength={26}
								onChange={handleChange}
								onFocus={handleInputFocus}
								pattern="^[^-\s][a-zA-ZÀ-ú ]*"
							/>
						</div>
					</div>

					{/* cardNumber */}
					<div className="form-container-numero">
						<div className="form-label">Número do Cartão</div>
						<div className="form-field">
							<InputMask
								mask="9999 9999 9999 9999"
								onChange={handleChange}
								onFocus={handleInputFocus}>
								<input
									type="text"
									name="cardNumber"
									placeholder="1234 1234 1234 1234"
									minLength="15"
								/>
							</InputMask>
						</div>
					</div>

					{/* expirationDate */}
					<div className="form-container-validade">
						<div className="form-label">Validade</div>
						<div className="form-field">
							<InputMask
								mask="99/99"
								onChange={handleChange}
								onFocus={handleInputFocus}>
								<input type="text" name="expirationDate" placeholder="mm/aa" />
							</InputMask>
						</div>
					</div>

					{/* cvc */}
					<div className="form-container-cvc">
						<div className="form-label">CVC</div>
						<div className="form-field">
							<InputMask
								mask="9999"
								onChange={handleChange}
								onFocus={handleInputFocus}>
								<input
									type="text"
									name="cvc"
									placeholder="123"
									pattern="[0-9]*"
								/>
							</InputMask>
						</div>
					</div>

					{/* parcelamento */}
					<div className="form-container-parcelamento">
						<div className="form-label">Parcelamento</div>
						<div className="form-field">
							<select
								name="installments"
								placeholder="Número de Parcelas"
								onChange={handleChange}
								defaultValue={'DEFAULT'}>
								<option value="DEFAULT" disabled>
									- Selecione a quantidade de parcelas -
								</option>
								{parcelamentoArray.map((parcelamento, index) => (
									<option key={index} value={index + 1}>
										{index + 1} x parcela de R$ {parcelamento} (Total: R${' '}
										{cartTotal})
									</option>
								))}
							</select>
						</div>
					</div>
				</form>
			</div>
		</div>
	);
};

export default CreditCard;
