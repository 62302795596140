import React, { useCallback, useRef } from 'react';
import '../RegistrarForm.styles.scss';

import ReactCanvasConfetti from 'react-canvas-confetti';

import IconConclusao from '../../../../assets/Icons/icon-conclusao.svg';

import StepperControl from '../StepperControl';

const RegistrarConclusão = ({
	steps,
	nextStep,
	currentStep,
	setCurrentStep,
	prevStep,
	setOpenTab,
	entrar,
	notModal
}) => {
	const refAnimationInstance = useRef(null);

	const getInstance = useCallback((instance) => {
		refAnimationInstance.current = instance;
	}, []);

	const makeShot = useCallback((particleRatio, opts) => {
		refAnimationInstance.current &&
			refAnimationInstance.current({
				...opts,
				origin: { y: 0.7 },
				particleCount: Math.floor(200 * particleRatio),
			});
	}, []);

	const fire = useCallback(() => {
		makeShot(0.25, {
			spread: 26,
			startVelocity: 55,
		});

		makeShot(0.2, {
			spread: 60,
		});

		makeShot(0.35, {
			spread: 100,
			decay: 0.91,
			scalar: 0.8,
		});

		makeShot(0.1, {
			spread: 120,
			startVelocity: 25,
			decay: 0.92,
			scalar: 1.2,
		});

		makeShot(0.1, {
			spread: 120,
			startVelocity: 45,
		});
	}, [makeShot]);

	return (
		<>
			<div className="form-complementary">
				<div className="confeti-container">
					<ReactCanvasConfetti refConfetti={getInstance} />
				</div>
				<div className="form-registro-conclusão">
					<div className="success-icon">
						<img onAnimationStart={fire} src={IconConclusao} alt="" />
						<span className="material-symbols-outlined">done</span>
					</div>
					<div className="success-txt">
						Conta Criada Com
						<br />
						<span>Sucesso!</span>
					</div>
				</div>
			</div>
			<div className="form-btns-container" style={{display: 'flex', justifyContent: 'center'}}>
				<StepperControl
					// entrar={entrar}
					// setOpenTab={setOpenTab}
					notModal={notModal}
					steps={steps}
					nextStep={nextStep}
					currentStep={currentStep}
					setCurrentStep={setCurrentStep}
					prevStep={prevStep}
				/>
			</div>
		</>
	);
};

export default RegistrarConclusão;
