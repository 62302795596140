import React from 'react';
import './FormCartão.styles.scss';
import CreditCard from './CreditCard';

const FormCartão = () => {
	return (
		<>
			<div className="cartao-container">
				<CreditCard />
			</div>
		</>
	);
};

export default FormCartão;
