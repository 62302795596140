import React, { useState, useContext } from 'react';
import './ModalAccount.styles.scss';

import EntrarForm from '../../LoginForms/EntrarForm/EntrarForm';
import RegistrarForm from '../../LoginForms/RegistrarForm/RegistrarForm';
import { AccountContext } from '../../../contexts/AccountContext';
import LoadingBars from '../../../assets/Loading/loading-bars.svg';

const ModalAccount = () => {
	const [openTab, setOpenTab] = useState('entrar');
	const { toggleAccountModal, isLoading } = useContext(AccountContext);

	const entrar = 'entrar';
	const registrar = 'registrar';

	const entrarHandler = (e) => {
		e.preventDefault();
		setOpenTab(entrar);
	};

	const registrarHandler = (e) => {
		e.preventDefault();
		setOpenTab(registrar);
	};
	return (
		<>
			<div className="account-modal-wrapper">
				{isLoading === true ? (
					<img className="account-loading-bars" src={LoadingBars} alt="" />
				) : (
					<div className="account-modal-container">
						<div className="login-top">
							<ul className="modal-vinculo-navbar-ul" role="tablist">
								<li>
									<a
										className={
											'list-anchor ' +
											(openTab === entrar
												? 'list-anchor-active-green'
												: 'list-anchor-inactive')
										}
										onClick={entrarHandler}
										data-toggle="tab"
										href="#link1"
										role="tablist">
										Entrar
									</a>
								</li>
								<li>
									<a
										className={
											'list-anchor ' +
											(openTab === registrar
												? 'list-anchor-active-yellow'
												: 'list-anchor-inactive')
										}
										onClick={registrarHandler}
										data-toggle="tab"
										href="#link2"
										role="tablist">
										Registrar
									</a>
								</li>
							</ul>
						</div>
						<button className="close-btn" onClick={toggleAccountModal}>
							<span className="material-symbols-outlined">close</span>
						</button>
						<div className="modal-vinculo-estudantes">
							<div
								className={openTab === entrar ? 'block' : 'hidden'}
								id="link1">
								<EntrarForm />
							</div>
							<div
								className={openTab === registrar ? 'block' : 'hidden'}
								id="link2">
								<RegistrarForm setOpenTab={setOpenTab} entrar={entrar} />
							</div>
						</div>
						{openTab === entrar && (
							<div className="login-form-bot">
								<div className="login-form-bot-content">
									<div className="bot-content-top">
										Em nossa <span className="blue-bold">loja</span> você terá:
									</div>
									<div className="login-form-list">
										<ul>
											<li>
												<span className="blue material-symbols-outlined">
													task_alt
												</span>
												Status do pedido.
											</li>
											<li>
												<span className="material-symbols-outlined">
													currency_exchange
												</span>
												Ofertas exclusivas.
											</li>
											<li>
												<span className="green material-symbols-outlined">
													shopping_cart_checkout
												</span>
												Compra rápida.
											</li>
										</ul>
									</div>
								</div>
							</div>
						)}
					</div>
				)}
			</div>
		</>
	);
};

export default ModalAccount;
