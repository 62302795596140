import React, { useContext } from 'react';
import './ModalVinculo.styles.scss';
import { VinculoContext } from '../../../contexts/VinculoContext';

const ModalVinculo = () => {
	const { toggleVinculoModal, selectedStudent, setSelectedStudent } =
		useContext(VinculoContext);
	const dados = selectedStudent?.student;

	const activeSchoolYear = dados?.schoolYears?.filter(sh => sh.deletedAt === null);

	const periodoLetivo = activeSchoolYear && activeSchoolYear.length? activeSchoolYear[0].CODPERLET : '';

	const filteredColigada = activeSchoolYear && activeSchoolYear.length ? dados?.Affiliates?.filter(
		(affiliate) => affiliate?.CODCOLIGADA === activeSchoolYear[0]?.CODCOLIGADA
	) : dados?.Affiliates?.filter(
		(affiliate) => affiliate?.CODCOLIGADA === dados?.schoolYears[0]?.CODCOLIGADA
	);

	function formataCPF(cpf) {
		//retira os caracteres indesejados...
		cpf = cpf?.replace(/[^\d]/g, '');

		//realizar a formatação...
		return cpf?.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
	}

	function formataTelefone(telefone) {
		//retira os caracteres indesejados...
		telefone = telefone?.replace(/[^\d]/g, '');

		//realizar a formatação...
		return telefone?.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
	}

	const closeVinculoModal = () => {
		setSelectedStudent('');
		toggleVinculoModal();
	};

	return (
		<>
			<div className="vinculo-modal-wrapper">
				<div className="vinculo-modal-container">
					<button className="close-btn" onClick={closeVinculoModal}>
						<span className="material-symbols-outlined">close</span>
					</button>
					<div className="vinculo-modal-content">
						<h2>Dados do(a) Estudante</h2>
						<h3>
							Nome: <span>{dados?.nome?.toLowerCase()}</span>
						</h3>
						<h3>
							CPF: <span>{formataCPF(dados?.cpf)}</span>
						</h3>
						<h3>
							RA: <span>{dados?.RA}</span>
						</h3>
						<h3>
							Período Letivo: <span>{periodoLetivo}</span>
						</h3>
						<h3>
							Unidade:{' '}
							<span>
								{dados === undefined
									? null
									: filteredColigada[0]?.name?.toLowerCase()}
							</span>
						</h3>
						<h3>
							E-mail:{' '}
							<span style={{ textTransform: 'lowercase' }}>
								{dados?.email?.toLowerCase()}
							</span>
						</h3>
						<h3>
							Telefone: <span>{formataTelefone(dados?.telefone1)}</span>
						</h3>

						<h2>Endereço do Estudante</h2>
						<h3>Cidade Natal:{' '}<span>{dados?.naturalidade} - {dados?.estadonatal} </span></h3>
						<h3>CEP:{' '}
							<span>
								{dados?.cep}
							</span>
						</h3>
						<h3>Cidade:{' '}
							<span>
								{dados?.cidade} - {dados?.estado}
							</span>
						</h3>
						<h3>Bairro:{' '}
							<span>
								{dados?.bairro}
							</span>
						</h3>
						<h3>Rua:{' '}
							<span>
								{dados?.rua}
							</span>
						</h3>
						<h3>Número:{' '}
							<span>
								{dados?.numero}
							</span>
						</h3>


						{dados?.Responsibles?.map((responsavel) => (
							<span key={responsavel.id}>
								{responsavel.parentesco === '9' ? (
									<h2>Dados do Responsável</h2>
								) : responsavel.parentesco === '7' ? (
									<h2>Dados da Mãe</h2>
								) : (
									<h2>Dados da Pai</h2>
								)}
								<h3>
									Nome: <span>{responsavel.nome?.toLowerCase()}</span>
								</h3>
								<h3>
									CPF: <span>{formataCPF(responsavel.cpf)}</span>
								</h3>
								<h3>
									E-mail:{' '}
									<span style={{ textTransform: 'lowercase' }}>
										{responsavel.email?.toLowerCase()}
									</span>
								</h3>
								<h3>
									Telefone:{' '}
									<span>{formataTelefone(responsavel.telefone1)}</span>
								</h3>
								<h3>Cidade Natal:{' '}<span>{dados?.naturalidade} - {dados?.estadonatal} </span></h3>
								<h3>CEP:{' '}
									<span>
										{responsavel?.cep}
									</span>
								</h3>
								<h3>Cidade:{' '}
									<span>
										{responsavel?.cidade} - {responsavel?.estado}
									</span>
								</h3>
								<h3>Bairro:{' '}
									<span>
										{responsavel?.bairro}
									</span>
								</h3>
								<h3>Rua:{' '}
									<span>
										{responsavel?.rua}
									</span>
								</h3>
								<h3>Número:{' '}
									<span>
										{responsavel?.numero}
									</span>
								</h3>
							</span>
						))}
					</div>
				</div>
			</div>
		</>
	);
};

export default ModalVinculo;
