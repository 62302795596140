import React, { useContext } from 'react';
import Modal from 'styled-react-modal';
import ModalAccount from './ModalAccount/ModalAccount';
import { AccountContext } from '../../contexts/AccountContext';

const StyledModal = Modal.styled`
	z-index: 30000;
	width: 100vw;
	height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  opacity: ${(props) => props.opacity};
  transition : all 0.3s ease-in-out;`;

const ModalAccountComponent = () => {
	const { isOpen, opacity, toggleAccountModal, afterOpen, beforeClose } =
		useContext(AccountContext);
	return (
		<StyledModal
			allowScroll={false}
			isOpen={isOpen}
			afterOpen={afterOpen}
			beforeClose={beforeClose}
			onBackgroundClick={toggleAccountModal}
			onEscapeKeydown={toggleAccountModal}
			opacity={opacity}
			backgroundProps={{ opacity }}>
			<ModalAccount />
		</StyledModal>
	);
};

export default ModalAccountComponent;
