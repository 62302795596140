import React, { useContext } from 'react';
import Modal from 'styled-react-modal';
import ModalVinculo from './ModalVinculo/ModalVinculo';
import { VinculoContext } from '../../contexts/VinculoContext';

const StyledModal = Modal.styled`
	z-index: 30000;
	width: 100vw;
	height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  opacity: ${(props) => props.opacity};
  transition : all 0.3s ease-in-out;`;

const ModalVinculoComponent = () => {
	const { isOpen, opacity, toggleVinculoModal, afterOpen, beforeClose } =
		useContext(VinculoContext);

	return (
		<StyledModal
			allowScroll={false}
			isOpen={isOpen}
			afterOpen={afterOpen}
			beforeClose={beforeClose}
			onBackgroundClick={toggleVinculoModal}
			onEscapeKeydown={toggleVinculoModal}
			opacity={opacity}
			backgroundProps={{ opacity }}>
			<ModalVinculo />
		</StyledModal>
	);
};

export default ModalVinculoComponent;
