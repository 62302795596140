import React, { useContext } from 'react';
import { FormContext } from '../../contexts/FormContext';
import { StepperContext } from '../../contexts/StepperContext';
import { AccountContext } from '../../contexts/AccountContext';
import './Form.styles.scss';
import { useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';
import axios from 'axios';
import BtnBase from '../Buttons/BtnBase/BtnBase';
import { isValidCPF } from '../../utils/isValidCPF';

const FormResponsável = ({ setOpenTab }) => {
	const formName = 'responsavel';
	const { newStudentData, setNewStudentData, Toast, createNewStudent } =
		useContext(AccountContext);

	const { ufs, cities, handleSelectedUf } = useContext(FormContext);
	const { nextStep, prevStep } = useContext(StepperContext);

	const { register, setValue, setFocus, handleSubmit } = useForm({
		defaultValues: { ...newStudentData },
	});

	// FETCH CEP
	const checkCEP = (e) => {
		if (!e.target.value) return;
		const cep = e.target.value.replace(/\D/g, '');
		axios
			.get(`https://viacep.com.br/ws/${cep}/json/`)
			.then((response) => {
				setValue(`${formName}.estado`, response.data.uf);
				setValue(`${formName}.cidade`, response.data.localidade);
				setValue(`${formName}.bairro`, response.data.bairro);
				setValue(`${formName}.rua`, response.data.logradouro);
				setFocus(`${formName}.numero`);
			})
			.catch((err) => console.log(err));
	};

	// SUBMIT FORM + SWAL ERROR VALIDATION
	const onSubmit = (formData) => {
		let {
			[formName]: { cpf, telefone },
		} = formData;
		const cpfString = cpf.replace(/\D/g, '');
		const telString = telefone.replace(/\D/g, '');
		if (cpfString === undefined || !isValidCPF(cpfString)) {
			Toast.fire({
				icon: 'error',
				title: 'CPF Inválido',
			});
			setFocus(`${formName}.cpf`);
		} else if (telString === undefined || telString.length < 11) {
			Toast.fire({
				icon: 'error',
				title: 'Telefone Incompleto',
			});
			setFocus(`${formName}.telefone`);
		} else {
			setNewStudentData(formData);
			createNewStudent(formData);
			nextStep();
		}
	};

	// CANCEL FORM
	const cancelForm = (e) => {
		e.preventDefault();
		setOpenTab(1);
	};

	return (
		<>
			<form className="form-wrapper" onSubmit={handleSubmit(onSubmit)}>
				<div className="forms-container">
					<div className="form-container-nome">
						<label htmlFor={formName + 'nome'} className="form-label">
							Nome Completo
						</label>
						<div className="form-field">
							<input
								className="form-field"
								{...register(`${formName}.nome`, { required: true })}
								placeholder="Nome e Sobrenome"
								type="text"
								minLength="10"
								maxLength="45"
								pattern="^[^-\s][a-zA-ZÀ-ú ]*"
							/>
						</div>
					</div>
					<div className="form-container-email">
						<label htmlFor={formName + 'email'} className="form-label">
							E-mail
						</label>
						<div className="form-field">
							<input
								{...register(`${formName}.email`, { required: true })}
								placeholder="Email"
								type="email"
								minLength="5"
								maxLength="45"
								pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
							/>
						</div>
					</div>

					<div className="form-container-cpf">
						<label htmlFor={formName + 'cpf'} className="form-label">
							CPF
						</label>
						<div className="form-field">
							<InputMask
								// mask options
								mask="999.999.999-99"
								alwaysShowMask={false}
								// input options
								type="text"
								placeholder="999.999.999-99"
								minLength="11"
								// react hook form register
								{...register(`${formName}.cpf`, {
									required: true,
								})}
							/>
						</div>
					</div>
					<div className="form-container-nascimento">
						<label
							htmlFor={formName + 'data_nascimento'}
							className="form-label">
							Data de Nascimento
						</label>
						<div className="form-field">
							<input
								{...register(`${formName}.data_nascimento`, { required: true })}
								placeholder="Data de Nascimento"
								type="date"
								max={new Date().toISOString().split("T")[0]}
								maxLength="8"
								pattern="[0-9]+"
							/>
						</div>
					</div>

					<div className="form-container-estado">
						<label htmlFor={formName + 'estado_natal'} className="form-label">
							Estado Natal
						</label>
						<div className="form-field">
							<select
								{...register(`${formName}.estado_natal`, { required: true })}
								onChange={handleSelectedUf}>
								{ufs.map((uf) => (
									<option key={uf.id} value={uf.sigla}>
										{uf.nome}
									</option>
								))}
							</select>
						</div>
					</div>

					<div className="form-container-cidade">
						<label htmlFor={formName + 'cidade_natal'} className="form-label">
							Cidade Natal
						</label>
						<div className="form-field">
							<select
								{...register(`${formName}.cidade_natal`, { required: true })}>
								{cities.map((city) => (
									<option key={city.id} value={city.nome}>
										{city.nome}
									</option>
								))}
							</select>
						</div>
					</div>

					<div className="form-container-cep">
						<label htmlFor={formName + 'cep'} className="form-label">
							CEP
						</label>
						<div className="form-field">
							<input
								{...register(`${formName}.cep`, {
									required: true,
								})}
								placeholder="64048-152"
								onBlur={checkCEP}
								// onInput={(e) => (e.target.value = e.target.value.slice(0, 8))}
								type="text"
								minLength="8"
								maxLength="8"
								pattern="[0-9]+"
							/>
							<button type="button" onClick={checkCEP}>
								<span className="material-symbols-outlined">
									travel_explore
								</span>
							</button>
						</div>
					</div>
					<div className="form-container-estado">
						<label htmlFor={formName + 'estado'} className="form-label">
							Estado
						</label>
						<div className="form-field">
							<input
								{...register(`${formName}.estado`, { required: true })}
								placeholder="Estado"
								type="text"
								disabled
							/>
						</div>
					</div>
					<div className="form-container-cidade">
						<label htmlFor={formName + 'cidade'} className="form-label">
							Cidade
						</label>
						<div className="form-field">
							<input
								{...register(`${formName}.cidade`, { required: true })}
								placeholder="Cidade"
								type="text"
								disabled
							/>
						</div>
					</div>
					<div className="form-container-bairro">
						<label htmlFor={formName + 'bairro'} className="form-label">
							Bairro
						</label>
						<div className="form-field">
							<input
								{...register(`${formName}.bairro`, { required: true })}
								placeholder="Bairro"
								type="text"
							/>
						</div>
					</div>
					<div className="form-container-rua">
						<label htmlFor={formName + 'rua'} className="form-label">
							Rua
						</label>
						<div className="form-field">
							<input
								{...register(`${formName}.rua`, { required: true })}
								placeholder="Rua"
								type="text"
							/>
						</div>
					</div>
					<div className="form-container-número">
						<label htmlFor={formName + 'numero'} className="form-label">
							Número
						</label>
						<div className="form-field">
							<input
								{...register(`${formName}.numero`, {
									required: true,
								})}
								placeholder="999"
								type="text"
								minLength="2"
								maxLength="6"
								pattern="[0-9]+"
							/>
						</div>
					</div>
					<div className="form-container-telefone">
						<label htmlFor={formName + 'telefone'} className="form-label">
							Celular
						</label>
						<div className="form-field">
							<InputMask
								// mask options
								mask="(99) 99999-9999"
								alwaysShowMask={false}
								// input options
								type={'tel'}
								minLength="8"
								placeholder="(86) 99999-9999"
								// react hook form register
								{...register(`${formName}.telefone`, {
									required: true,
								})}
							/>
						</div>
					</div>
				</div>

				<div className="stepper-control-wrapper">
					{formName === 'estudante' ? (
						<div className="stepper-control-container">
							<BtnBase
								OnClick={cancelForm}
								BtnTxt={'Cancelar'}
								BtnClass={'btn-red-sm'}
							/>
							<BtnBase
								Type={'submit'}
								BtnTxt={'Próximo'}
								BtnClass={'btn-green-sm'}
							/>
						</div>
					) : formName === 'mae' ? (
						<div className="stepper-control-container">
							<BtnBase
								OnClick={prevStep}
								BtnTxt={'Anterior'}
								BtnClass={'btn-grey-sm'}
							/>
							<BtnBase
								Type={'submit'}
								BtnTxt={'Próximo'}
								BtnClass={'btn-green-sm'}
							/>
						</div>
					) : (
						<div className="stepper-control-container">
							<BtnBase
								OnClick={prevStep}
								BtnTxt={'Anterior'}
								BtnClass={'btn-grey-sm'}
							/>
							<BtnBase
								Type={'submit'}
								BtnTxt={'Confirmar'}
								BtnClass={'btn-blue-sm'}
							/>
						</div>
					)}
				</div>
			</form>
		</>
	);
};

export default FormResponsável;
