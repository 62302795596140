import React, { useContext, useState } from "react";
import "./ModalVinculação.styles.scss";
import { ProductsContext } from "../../../contexts/ProductsContext";
import { ModalContext } from "../../../contexts/ModalContext";
import { CartContext } from "../../../contexts/CartContext";
import { unidadeRetirada } from "../../../utils/affiliatesInfo";
import { percentage } from "../../../utils/percentage";
import { calculateInstallments } from "../../../utils/calculateInstallments";
import VinculaçãoContainer from "./VinculaçãoContainer/VinculaçãoContainer";

const ModalVinculação = () => {
  const { toggleModal } = useContext(ModalContext);
  const {
    selectedStudent,
    setSelectedStudent,
    addItemToCartHandler,
    alreadyStudent,
  } = useContext(CartContext);
  const { selectedSubproduct, selectedProduct } = useContext(ProductsContext);
  const NOME = selectedSubproduct?.NOME;
  const TIPO = selectedSubproduct?.TIPO;
  // const TURNO = selectedSubproduct?.TURNO;
  const title = NOME.toLowerCase();
  const PRECO = selectedSubproduct?.PRECO;
  const DESCONTO = selectedSubproduct?.DESCONTO;
  const paymentPlan = selectedSubproduct?.paymentPlan;
  const parcelas = selectedSubproduct?.parcelas;

  const [openList, setOpenList] = useState(false);

  const COLIGADA = unidadeRetirada(selectedSubproduct?.CODCOLIGADA);
  const discountAmount = PRECO - DESCONTO;

  const percentageDiscount = percentage(discountAmount, PRECO);

  const image = selectedProduct.image;

  let nomeEstudante = !selectedStudent
    ? "Selecionar estudante"
    : selectedStudent?.student?.nome?.toLowerCase();

  const closeModalHandler = () => {
    toggleModal();
    setSelectedStudent();
  };

  return (
    <>
      <div className="modal-vinculo-wrapper">
        <div className="modal-vinculo-container">
          <div className="modal-vinculo-header">
            <div className="modal-vinculo-title">
              <div className="modal-vinculo-title-big">
                <h2>Item em Vinculação:</h2>
              </div>
              <p>
                Vincule um estudante ao item selecionado para adicioná-lo ao
                carrinho
              </p>
            </div>
            <div className="modal-cart-item">
              <div className="modal-cart-item-container">
                <div className="modal-cart-item-img-container">
                  <img src={image} alt={`${NOME}`} />
                </div>
                <div className="modal-cart-item-info-container">
                  <div className="modal-cart-item-info-top">
                    <div className="modal-cart-item-name">
                      {title.length <= 35
                        ? title
                        : title.substr(0, 35) + "\u2026"}
                      {(TIPO === "S" || TIPO === "M") && (
                        <div className="unit-name">Unidade - {COLIGADA}</div>
                      )}
                    </div>
                  </div>
                  <div className="modal-cart-item-info-mid">
                    <div className="modal-cart-item-estudante">
                      <span className="iconVinc material-symbols-outlined">
                        link
                      </span>
                      {nomeEstudante?.length <= 35
                        ? nomeEstudante
                        : nomeEstudante?.substr(0, 35) + "\u2026"}
                    </div>
                  </div>
                  <div className="modal-cart-item-info-bot">
                    <div className="modal-cart-item-price">
                      {PRECO !== DESCONTO && (
                        <h3>
                          de{" "}
                          <span className="price-blue">
                            R${" "}
                            {`${calculateInstallments(
                              paymentPlan,
                              TIPO,
                              parcelas
                            )}x${(
                              PRECO /
                              calculateInstallments(paymentPlan, TIPO, parcelas)
                            )?.toFixed(2)}`}
                          </span>
                        </h3>
                      )}
                      <h4>
                        Por{" "}
                        <span className="price-green">
                          R${" "}
                          {`${calculateInstallments(
                            paymentPlan,
                            TIPO,
                            parcelas
                          )}x${(
                            DESCONTO /
                            calculateInstallments(paymentPlan, TIPO, parcelas)
                          )?.toFixed(2)}`}
                        </span>
                        {percentageDiscount < 1 ? null : (
                          <span className="porcentagem-desconto">
                            ({percentageDiscount}% desconto)
                          </span>
                        )}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button className="close-btn" onClick={closeModalHandler}>
              <span className="material-symbols-outlined">close</span>
            </button>
          </div>
          {!openList && (
            <div className="card-container">
              <div
                className="card-sou-estudante"
                onClick={() => alreadyStudent(selectedSubproduct)}
              >
                <span class="material-symbols-outlined">person</span>
                <div>
                  <h1>Sou Aluno</h1>
                  <p>Selecione essa opção caso a compra seja para você</p>
                </div>
              </div>

              <div
                className="card-sou-estudante"
                onClick={() => setOpenList(true)}
              >
                <span class="material-symbols-outlined">group</span>
                <div>
                  <h1>Lista de Alunos</h1>
                  <p>
                    Selecione essa opção caso a compra seja para outra pessoa
                  </p>
                </div>
              </div>
            </div>
          )}
          {openList && !!selectedProduct && (
            <VinculaçãoContainer
              addItemToCartHandler={addItemToCartHandler}
              closeModalHandler={closeModalHandler}
              selectedStudent={selectedStudent}
              selectedSubproduct={selectedSubproduct}
              setOpenList={setOpenList}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default ModalVinculação;
