import "./CuponContainer.styles.scss";
import { useContext, useEffect, useState } from "react";
import { CartContext } from "../../../contexts/CartContext";
import { GlobalContext } from "../../../contexts/GlobalContext";

const CuponContainer = () => {
  const { getCouponsByCartId, applyCouponOnCart, removeCouponFromCartItems } =
    useContext(CartContext);
  const { carrinho } = useContext(GlobalContext);
  const bag = carrinho.find(
    (bag) => !!bag.cartItems.find((cartItem) => !!cartItem.itemCouponId)
  );
  const itemCoupon = !!bag
    ? bag.cartItems.find((cartItem) => !!cartItem.itemCouponId).itemCoupon
    : undefined;

  const [loading, setLoading] = useState(true);
  const [listCoupons, setListCoupons] = useState([]);

  useEffect(() => {
    const getCoupons = async () => {
      setLoading(true);
      const response = await getCouponsByCartId(carrinho[0].cartId);
      setListCoupons(response);
      setLoading(false);
    };

    if (carrinho.length) {
      getCoupons();
    }
  }, [carrinho, getCouponsByCartId]);

  const handleClickCoupon = (code) => {
    applyCouponOnCart(code);
  };

  const handleRemoveCoupon = (e) => {
    e.preventDefault();
    removeCouponFromCartItems();
  };

  return (
    <>
      {!!listCoupons && !!listCoupons.length && (
        <div className="aplicar-cupom-div">
          {!!itemCoupon ? (
            <div class="aplicar-cupom-applied">
              <p class="aplicar-cupom-applied-text">
                Cupom já aplicado!
                <br />
                <b>CÓDIGO: {itemCoupon.coupon.code}</b>
              </p>
              <button
                className="remover-cupom-button"
                title="Remover Cupom"
                onClick={handleRemoveCoupon}
              >
                <span>
                  <span class="material-symbols-outlined">close</span>Remover
                </span>
              </button>
            </div>
          ) : (
            <div class="aplicar-cupom-form">
              <h1>Cupom de Desconto</h1>
              <span className="aplicar-cupom-description">
                Após aplicar cupom no carrinho, qualquer alteração realizada no
                mesmo irá remover o cupom. É possível aplicar somente um cupom
                por compra.
              </span>
            </div>
          )}

          {!itemCoupon && !loading && (
            <div className="aplicar-cupom-lista">
              <h1 className="cupom-lista-title">Cupons Disponíveis</h1>
              {listCoupons.map((itemCoupon) => (
                <div key={itemCoupon.id} class="cupom-botao-container">
                  <button
                    className="cupom-botao"
                    onClick={() => handleClickCoupon(itemCoupon.coupon.code)}
                  >
                    <span class="button-center">
                      <span class="material-symbols-outlined icon">
                        confirmation_number
                      </span>
                      <span class="button-text">
                        Aplicar Cupom de {itemCoupon.coupon.percentage}% :{" "}
                        <b>{itemCoupon.coupon.code}</b>
                      </span>
                    </span>
                  </button>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default CuponContainer;
