import React, { useContext } from 'react';
import './NovaVinculação.styles.scss';

// STEPPER
import Stepper from './Stepper/Stepper';

// CONTEXT
import { StepperContext } from '../../../../contexts/StepperContext';

// FORM STEPS
import FormEstudante from '../../../FormVinculo/FormEstudante';
import FormMãe from '../../../FormVinculo/FormMãe';
import FormResponsável from '../../../FormVinculo/FormResponsável';
import FormCompleto from '../../../FormVinculo/FormCompleto';

const NovaVinculação = ({ setOpenTab }) => {
	const { currentStep } = useContext(StepperContext);

	// const steps = ['Estudante', 'Mãe', 'Responsável', 'Completo'];
	const steps = ['Estudante', 'Responsável', 'Completo'];

	const displayStep = (step) => {
		switch (step) {
			case 1:
				return <FormEstudante setOpenTab={setOpenTab} />;
			// case 2:
			// 	return <FormMãe />;
			case 2:
				return <FormResponsável />;
			case 3:
				return <FormCompleto setOpenTab={setOpenTab} />;
			default:
		}
	};

	return (
		<>
			<div className="nova-vinculação-container">
				<div className="stepper-steps">
					<Stepper steps={steps} />
				</div>
				<div className="stepper-content">{displayStep(currentStep)}</div>
			</div>
		</>
	);
};

export default NovaVinculação;
