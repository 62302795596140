import React, { useContext } from 'react';
import './StepperControl.styles.scss';
import Swal from 'sweetalert2';
import { AccountContext } from '../../../contexts/AccountContext';
import BtnBase from '../../Buttons/BtnBase/BtnBase';

const StepperControl = ({
	nextStep,
	currentStep,
	prevStep,
	notModal
}) => {
	const { account, logIn } = useContext(AccountContext);

	const handleSubmit = () => {
		if (
			!account.register.cpf ||
			!account.register.birthDate ||
			!account.register.cellPhone ||
			!account.register.zipcode ||
			!account.register.state ||
			!account.register.city ||
			!account.register.neighborhood ||
			!account.register.street ||
			!account.register.houseNumber
		) {
			Swal.fire({
				icon: 'error',
				iconColor: '#ff6363',
				title: 'Formulário Incompleto',
				text: 'Preencha todos os campos do formulário para prosseguir.',
				confirmButtonColor: '#1e3799',
				confirmButtonText: 'OK',
			});
		} else {
			nextStep();
		}
	};

	const handleNext = () => {
		if (
			!account.name ||
			!account.email ||
			!account.password ||
			!account.password2
		) {
			Swal.fire({
				icon: 'error',
				iconColor: '#ff6363',
				title: 'Formulário Incompleto',
				text: 'Preencha todos os campos do formulário para prosseguir.',
				confirmButtonColor: '#1e3799',
				confirmButtonText: 'OK',
			});
		} else {
			nextStep();
		}
	};

	const handleReturn = () => {
		const email = account.access.email;
		const password = account.access.password;
		logIn(email, password, notModal);
	};

	return (
		<>
			{currentStep === 2 && (
				<BtnBase
					OnClick={prevStep}
					BtnTxt={'Voltar'}
					BtnClass={'btn-grey-sm'}
				/>
			)}
			<BtnBase
				OnClick={
					currentStep === 2
						? handleSubmit
						: currentStep === 3
						? handleReturn
						: handleNext
				}
				BtnTxt={
					currentStep === 2
						? 'Criar Conta'
						: currentStep === 3
						? 'Acessar'
						: 'Próximo'
				}
				BtnClass={
					currentStep === 2
						? 'btn-blue-sm'
						: currentStep === 3
						? 'btn-yellow-sm'
						: 'btn-green-sm'
				}
			/>
		</>
	);
};

export default StepperControl;
