import React, { useState, useEffect, useRef } from 'react';
import './StepperCheckout.styles.scss';

const StepperCheckout = ({ steps, currentStep }) => {
	const [newStep, setNewStep] = useState([]);
	const stepsRef = useRef();

	const updateStep = (stepNumber, steps) => {
		const newSteps = [...steps];
		let count = 0;
		while (count < newSteps.length) {
			//current step
			if (count === stepNumber) {
				newSteps[count] = {
					...newSteps[count],
					highlighted: true,
					selected: true,
					completed: true,
				};
				count++;
			}

			//step completed
			else if (count < stepNumber) {
				newSteps[count] = {
					...newSteps[count],
					highlighted: false,
					selected: true,
					completed: true,
				};
				count++;
			}
			//step pending
			else {
				newSteps[count] = {
					...newSteps[count],
					highlighted: false,
					selected: false,
					completed: false,
				};
				count++;
			}
		}

		return newSteps;
	};

	useEffect(() => {
		const stepsState = steps.map((step, index) =>
			Object.assign(
				{},
				{
					description: step,
					completed: false,
					highlighted: index === 0 ? true : false,
					selected: index === 0 ? true : false,
				}
			)
		);

		stepsRef.current = stepsState;
		const current = updateStep(currentStep - 1, stepsRef.current);
		setNewStep(current);
	}, [steps, currentStep]);

	const stepsDisplay = newStep.map((step, index) => {
		return (
			<div
				key={index}
				className={
					index !== newStep.length - 1
						? 'w-full flex items-center'
						: 'flex items-center'
				}>
				<div className="relative flex flex-col items-center text-teal-600">
					<div
						className={`step-number text-lg rounded-full transition duration-500 ease-in-out h-9 w-9 flex items-center justify-center py-3  ${
							step.selected ? 'step-selected text-white font-bold' : ''
						}`}>
						{step.completed ? (
							<span className="check-mark text-xl material-symbols-outlined">
								done
							</span>
						) : (
							index + 1
						)}
					</div>
					<div
						className={`step-text w-max md:text-lg sm:text-sm text-xs absolute bottom-5  text-center mb-7 w-42 ${
							step.highlighted ? 'step-highlited' : 'text-gray-400'
						}`}>
						{step.description}
					</div>
				</div>
				<div
					className={`flex-auto border-t-2 transition duration-500 ease-in-out  ${
						step.completed ? 'step-completed' : 'border-gray-300 '
					}  `}></div>
			</div>
		);
	});

	return (
		<div className="checkout-stepsDisplay-container flex justify-between items-center">
			{stepsDisplay}
		</div>
	);
};
export default StepperCheckout;
