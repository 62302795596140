import React, { useState, useContext } from 'react';
import '../RegistrarForm.styles.scss';
import { AccountContext } from '../../../../contexts/AccountContext';
import { useForm } from 'react-hook-form';
import BtnBase from '../../../Buttons/BtnBase/BtnBase';

const RegistrarAcesso = ({ nextStep }) => {
	const formName = 'access';
	const { account, setAccount, Toast, termsContracts } =
		useContext(AccountContext);
	const { register, setFocus, handleSubmit } = useForm({
		defaultValues: { ...account },
	});

	// PASSWORD EYE TOGGLE
	const [values, setValues] = useState({
		password: '',
		showPassword: false,
	});

	const handleClickShowPassword = () => {
		setValues({ ...values, showPassword: !values.showPassword });
	};

	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};

	// SUBMIT FORM - STEP 1
	const onSubmit = (formData) => {
		let {
			[formName]: { password, senha_confirm, terms },
		} = formData;

		if (terms === false) {
			Toast.fire({
				icon: 'error',
				iconColor: '#fff',
				color: '#fff',
				background: '#ff6363',
				title: 'Termos e Condições não aceitos',
			});
		} else if (password !== senha_confirm) {
			Toast.fire({
				icon: 'error',
				iconColor: '#fff',
				color: '#fff',
				background: '#ff6363',
				title: 'Senhas nao correspondem',
			});
			setFocus(`${formName}.senha_confirm`);
			handleClickShowPassword();
		} else {
			setAccount(formData);
			nextStep();
		}
	};

	return (
		<>
			<form className="form-essential" onSubmit={handleSubmit(onSubmit)}>
				<div className="form-essential-title">
					<div className="form-essential-title-top">
						Passo 1/2 - Dados de Acesso
					</div>
					<div className="form-essential-title-link">
						Dados utilizados para acessar a loja.
					</div>
				</div>
				<div className="form-essential-content">
					<div className="form-container-nome">
						<label htmlFor={formName + 'name'} className="form-label">
							Nome Completo
						</label>
						<div className="form-field">
							<input
								className="form-field"
								{...register(`${formName}.name`, { required: true })}
								placeholder="Nome e Sobrenome"
								type="text"
								minLength="10"
								maxLength="45"
								pattern="^[^-\s][a-zA-ZÀ-ú ]*"
							/>
						</div>
					</div>

					<div className="form-container-email">
						<label htmlFor={formName + 'email'} className="form-label">
							E-mail
						</label>
						<div className="form-field">
							<input
								{...register(`${formName}.email`, { required: true })}
								placeholder="Email"
								type="email"
								minLength="5"
								maxLength="45"
								pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
							/>
						</div>
					</div>

					<div className="form-container-senha">
						<label htmlFor={formName + 'password'} className="form-label">
							Senha
						</label>
						<div className="form-field">
							<input
								{...register(`${formName}.password`, { required: true })}
								placeholder="Senha"
								type={values.showPassword ? 'text' : 'password'}
								minLength="6"
								maxLength="20"
							/>
							<button
								type="button"
								onClick={handleClickShowPassword}
								onMouseDown={handleMouseDownPassword}>
								{values.showPassword ? (
									<span className="material-symbols-outlined">visibility</span>
								) : (
									<span className="material-symbols-outlined">
										visibility_off
									</span>
								)}
							</button>
						</div>
					</div>

					<div className="form-container-senha">
						<label htmlFor={formName + 'senha_confirm'} className="form-label">
							Repetir Senha
						</label>
						<div className="form-field">
							<input
								{...register(`${formName}.senha_confirm`, { required: true })}
								placeholder="Repetir Senha"
								type={values.showPassword ? 'text' : 'password'}
								minLength="6"
								maxLength="20"
							/>
							<button
								type="button"
								onClick={handleClickShowPassword}
								onMouseDown={handleMouseDownPassword}>
								{values.showPassword ? (
									<span className="material-symbols-outlined">visibility</span>
								) : (
									<span className="material-symbols-outlined">
										visibility_off
									</span>
								)}
							</button>
						</div>
					</div>

					<div className="form-container-termos">
						<div className="filter-option">
							<label className="container">
								Aceitar{' '}
								<a
									className='blue-bold'
									target="_blank"
									rel="noopener noreferrer"
									href={termsContracts}>
									Termos de Uso{' '}
								</a>
								da plataforma.
								<input
									{...register(`${formName}.terms`, { required: true })}
									type="checkbox"
								/>
								<span className="checkmark"></span>
							</label>
						</div>
					</div>
				</div>
				<div className="form-btns-container">
					<BtnBase
						Type={'submit'}
						BtnTxt={'Próximo'}
						BtnClass={'btn-green-sm'}
					/>
				</div>
			</form>
		</>
	);
};

export default RegistrarAcesso;
