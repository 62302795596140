import { useState, useContext, useEffect } from "react";
import { ProductsContext } from "../../contexts/ProductsContext";
import "./CompraPage.styles.scss";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import StepperCheckout from "../../components/CheckoutStepper/StepperCheckout";
import EntrarForm from "../../components/LoginForms/EntrarForm/EntrarForm";
import ModalComponent from "../../components/Modal/ModalComponent";
import ModalVinculoComponent from "../../components/ModalVinculo/ModalVinculoComponent";
import ModalAccountComponent from "../../components/ModalAccount/ModalAccountComponent";
import RegistrarForm from "../../components/LoginForms/RegistrarForm/RegistrarForm";
import { AccountContext } from "../../contexts/AccountContext";
import VinculateStudent from "../../components/VinculateStudent/VinculateStudent";
import ItemDisplay from "../../components/ItemDisplay/ItemDisplay";
import { GlobalContext } from "../../contexts/GlobalContext";
import Payment from "../../components/Payment/Payment";
import PaymentCart from "../../components/PaymentCart/PaymentCart";
import { useMediaQuery } from "react-responsive";

const CompraPage = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [typeForm, setTypeForm] = useState(1);
  const { selectedSubproduct, selectedProduct } = useContext(ProductsContext);
  const { carrinho, bagsData } = useContext(GlobalContext);
  const { loggedUserData } = useContext(AccountContext);
  const navigate = useNavigate();

  const isNotMobileDevice = useMediaQuery({
    query: "(min-device-width: 769px)",
  });

  const steps = ["Autenticação", "Selecionar Aluno", "Pagamento"];

  const nextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  useEffect(() => {
    if (!selectedSubproduct) {
      if (!carrinho || (!carrinho.length && !bagsData.length)) {
        navigate("/");
      }
      setCurrentStep(3);
    } else if (!!loggedUserData) {
      setCurrentStep(2);
    }
  }, [loggedUserData, selectedSubproduct, carrinho, bagsData, navigate]);

  const displayStep = (step) => {
    switch (step) {
      case 1:
        return (
          <div className="mt-5 flex flex-col justify-center">
            {typeForm ? (
              <EntrarForm notModal={true} />
            ) : (
              <RegistrarForm notModal={true} />
            )}
            {typeForm ? (
              <p className="change-form text-center">
                Ainda não é cliente?
                <br />
                Crie sua conta clicando{" "}
                <span
                  className="text-link"
                  onClick={() => setTypeForm(!typeForm)}
                >
                  aqui
                </span>
                !
              </p>
            ) : (
              <p className="change-form text-center">
                Já é cliente?
                <br />
                Faça o login clicando{" "}
                <span
                  className="text-link"
                  onClick={() => setTypeForm(!typeForm)}
                >
                  aqui
                </span>
                !
              </p>
            )}
          </div>
        );
      case 2:
        return <VinculateStudent nextStep={nextStep} />;
      default:
        return <Payment />;
    }
  };

  if (currentStep === 3 || !!selectedProduct || !!selectedSubproduct) {
    return (
      <div className="compra-main-container">
        <Helmet>
          <title>Checkout | Propósito Store</title>
          <meta name="description" content="Checkout Propósito Store" />
        </Helmet>
        <ModalComponent />
        <ModalAccountComponent />
        {loggedUserData && <ModalVinculoComponent />}
        <div className="step-content-container">
          <div className="checkout-stepper">
            <StepperCheckout steps={steps} currentStep={currentStep} />
            {displayStep(currentStep)}
          </div>
        </div>
        {currentStep !== 3 ? (
          <ItemDisplay />
        ) : isNotMobileDevice ? (
          <PaymentCart />
        ) : (
          <></>
        )}
      </div>
    );
  }

  return <></>;
};

export default CompraPage;
