function formatPriceBR(valor) {
  if (typeof valor === "number") {
    return valor.toLocaleString("pt-BR", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }

  if (typeof valor === "string") {
    const numero = parseFloat(valor.replace(",", "."));
    return numero.toLocaleString("pt-BR", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }

  return "";
}

export default formatPriceBR;
