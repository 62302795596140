import React, { useState, useContext } from 'react';
import './EntrarForm.styles.scss';
import BtnBase from '../../Buttons/BtnBase/BtnBase';
import { AccountContext } from '../../../contexts/AccountContext';

const EntrarForm = ({ notModal }) => {
	const { logIn, forgotPass, Toast } = useContext(AccountContext);

	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');

	const [values, setValues] = useState({
		password: '',
		showPassword: false,
	});

	const handleClickShowPassword = () => {
		setValues({ ...values, showPassword: !values.showPassword });
	};

	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};

	const handleLogin = (e) => {
		e.preventDefault();

		if (
			email === undefined ||
			email.length < 1 ||
			password === undefined ||
			password.length < 1
		) {
			Toast.fire({
				icon: 'error',
				iconColor: '#fff',
				color: '#fff',
				background: '#ff6363',
				title: 'Preencha as informações de login para prosseguir',
			});
		} else {
			logIn(email, password, notModal);
		}
	};

	const handleForgot = () => {
		if (email === undefined || email.length < 10) {
			Toast.fire({
				icon: 'error',
				iconColor: '#fff',
				color: '#fff',
				background: '#ff6363',
				title: 'Email Incompleto',
			});
		} else {
			forgotPass(email);
		}
	};

	return (
		<>
			<form className="login-form-wrapper" onSubmit={handleLogin}>
				<div className="login-form-top">
					<div className="login-form-big">
						Acesse sua <span>conta!</span>
						<div className="message"></div>
					</div>
				</div>

				<div className="login-form-mid">
					<div className="form-login-wrapper">
						<div className="form-container-email">
							<label className="form-label">E-mail:</label>
							<div className="form-field">
								<input
									name="email"
									placeholder="Email"
									type="email"
									value={email}
									onChange={(e) => setEmail(e.target.value)}
								/>
							</div>
						</div>

						<div className="form-container-senha">
							<label className="form-label">Senha:</label>
							<div className="form-field">
								<input
									name="password"
									placeholder="senha"
									type={values.showPassword ? 'text' : 'password'}
									value={password}
									onChange={(e) => setPassword(e.target.value)}
								/>
								<button
									type="button"
									onClick={handleClickShowPassword}
									onMouseDown={handleMouseDownPassword}>
									{values.showPassword ? (
										<span className="material-symbols-outlined">
											visibility
										</span>
									) : (
										<span className="material-symbols-outlined">
											visibility_off
										</span>
									)}
								</button>
							</div>
						</div>
					</div>
					<div className="form-btns-container">
						<BtnBase
							Type={'submit'}
							OnClick={handleLogin}
							BtnTxt={'Acessar'}
							BtnClass={'btn-yellow-md'}
						/>
						<button
							className="pass-forgot"
							onClick={handleForgot}
							type="button">
							<span className="pass-forgot-txt">Esqueci a senha </span>
							<span className="pass-forgot-arrow material-symbols-outlined">
								arrow_right_alt
							</span>
						</button>
					</div>
				</div>
			</form>
		</>
	);
};

export default EntrarForm;
