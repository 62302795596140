import React, { useContext, useLayoutEffect, useState } from "react";
import "./CartDropdown.styles.scss";
import CartItem from "../CarItem/CartItem";

import { useNavigate } from "react-router-dom";

import { CartContext } from "../../../contexts/CartContext";
import BtnBase from "../../Buttons/BtnBase/BtnBase";
import { GlobalContext } from "../../../contexts/GlobalContext";
import { AccountContext } from "../../../contexts/AccountContext";
import LoadingBars from "../../../assets/Loading/loading-bars.svg";
import { ProductsContext } from "../../../contexts/ProductsContext";

const CartDropdown = () => {
  const { setSelectedSubproduct, setSelectedProduct } =
    useContext(ProductsContext);
  const { cartTotal, toggleCart, loading } = useContext(CartContext);
  const { carrinho } = useContext(GlobalContext);
  const { isLoggedIn } = useContext(AccountContext);

  let navigate = useNavigate();

  const toggleCartHandler = () => toggleCart();

  const fecharPedidoHandler = () => {
    toggleCartHandler();
    setSelectedSubproduct();
    setSelectedProduct();
    navigate("/compra");
    // window.location.reload();
  };

  function useWindowSize() {
    const [size, setSize] = useState(0);
    useLayoutEffect(() => {
      function updateSize() {
        setSize(window.innerHeight);
      }
      window.addEventListener("resize", updateSize);
      updateSize();
      return () => window.removeEventListener("resize", updateSize);
    }, []);
    return size;
  }

  const height = useWindowSize();

  return (
    <>
      <div className="cart-dropdown-wrapper" style={{ height: height }}>
        <div className="cart-dropdown-container">
          <div className="cart-top">
            <div className="cart-title">Carrinho de Compras</div>
            <div className="cart-close">
              <button onClick={toggleCartHandler}>
                <span className="close-btn material-symbols-outlined">
                  close
                </span>
              </button>
            </div>
          </div>
          {loading === true ? (
            <div className="loading-container">
              <img className="big-loading-bars" src={LoadingBars} alt="" />
            </div>
          ) : (
            <div className="cart-content-container">
              <div className="cart-items-container">
                {carrinho?.map((bag) =>
                  bag.cartItems.map((cartItem, index) => (
                    <CartItem
                      key={cartItem.cartItemid}
                      bag={bag}
                      cartItem={cartItem}
                    />
                  ))
                )}
              </div>
              {isLoggedIn && carrinho.length > 0 && (
                <div className="cart-orçamento">
                  <div className="orçamento-total">
                    <div className="orçamento-total-content">
                      <div className="orçamento-subtotal">SUBTOTAL</div>
                      <div className="orçamento-número">
                        <h4>
                          <span className="price-green">R$ {cartTotal}</span>
                          {/* à vista */}
                        </h4>
                        {/* <h3>
													ou{' '}
													<span className="price-blue">
														R$ {cartTotalParcelado}
													</span>{' '}
													parcelado
												</h3> */}
                      </div>
                    </div>
                  </div>
                  <div className="btns-container">
                    {/* {isMobileDevice && (
											<BtnBase
												BtnTxt={'Retornar'}
												BtnClass={'btn-grey'}
												OnClick={toggleCartHandler}
											/>
										)} */}

                    <BtnBase
                      OnClick={fecharPedidoHandler}
                      BtnTxt={"Proceder Com Pedido"}
                      BtnClass={"btn-cart"}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default CartDropdown;
