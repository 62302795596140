import React, { useState, useContext } from 'react';
import './RegistrarForm.styles.scss';

// STEPS
import RegistrarAcesso from './Steps/RegistrarAcesso';
import RegistrarAdicionais from './Steps/RegistrarAdicionais';
import RegistrarConclusão from './Steps/RegistrarConclusão';
import { AccountContext } from '../../../contexts/AccountContext';

const RegistrarForm = ({ setOpenTab, entrar, notModal }) => {
	const { account } = useContext(AccountContext);

	const [currentStep, setCurrentStep] = useState(1);
	const steps = ['Dados de Acesso', 'Informações Adicionais', 'Conclusão'];
	const nextStep = () => {
		setCurrentStep(currentStep + 1);
	};

	const prevStep = () => {
		setCurrentStep(currentStep - 1);
	};

	const primeiroNome = account?.access?.name;

	const firstName = () => {
		if (account === '') return 'usuário';
		else return primeiroNome?.split(' ')[0];
	};


	const displayStep = (step) => {
		switch (step) {
			case 1:
				return (
					<RegistrarAcesso
						nextStep={nextStep}
						prevStep={prevStep}
						currentStep={currentStep}
						steps={steps}
					/>
				);
			case 2:
				return (
					<RegistrarAdicionais
						nextStep={nextStep}
						prevStep={prevStep}
						currentStep={currentStep}
						setCurrentStep={setCurrentStep}
						steps={steps}
					/>
				);
			case 3:
				return (
					<RegistrarConclusão
						notModal={notModal}
						entrar={entrar}
						setOpenTab={setOpenTab}
						nextStep={nextStep}
						prevStep={prevStep}
						currentStep={currentStep}
						setCurrentStep={setCurrentStep}
						steps={steps}
					/>
				);
			default:
		}
	};

	return (
		<>
			<div className="registro-form-content">
				<div className="registro-form-top">
					{currentStep !== 3 && (
						<div className="registro-form-big">
							Crie sua <span>conta!</span>
						</div>
					)}
					{currentStep === 3 && (
						<div className="registro-form-big">
							Bem vindo(a), <span id="first-name-register">{firstName()}</span>!
						</div>
					)}
				</div>
				<div className="registro-form-mid">
					<div className="form-registro-wrapper">
						{displayStep(currentStep)}
					</div>
				</div>
			</div>
		</>
	);
};

export default RegistrarForm;
