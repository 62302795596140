import "./Payment.styles.scss";
import { useCallback, useContext, useEffect } from "react";
import LoadingBars from "../../assets/Loading/loading-bars.svg";
import IconBoleto from "../../assets/Icons/icon-boleto.svg";
import IconCardMaster from "../../assets/Icons/icon-card-master.svg";
import { AccountContext } from "../../contexts/AccountContext";
import { CartContext } from "../../contexts/CartContext";
import { PaymentContext } from "../../contexts/PaymentContext";
import FormCartão from "../CheckoutForms/FormCartão";
import formatPriceBR from "../../utils/formatPriceBR";
import CuponContainer from "./CuponContainer/CuponContainer";
import PaymentCart from "../PaymentCart/PaymentCart";
import { useMediaQuery } from "react-responsive";
import { GlobalContext } from "../../contexts/GlobalContext";

const Payment = () => {
  const { termsContracts } = useContext(AccountContext);
  const { carrinho } = useContext(GlobalContext);
  const { cartTotal, cartTotalParcelado } = useContext(CartContext);
  const {
    payment,
    paymentMethod,
    setPaymentMethod,
    boleto,
    credito,
    paymentMethodIds,
    setPaymentMethodId,
    isLoading,
    acceptedTerms,
    setAcceptedTerms,
    handleSubmitPayment,
  } = useContext(PaymentContext);

  const isNotMobileDevice = useMediaQuery({
    query: "(min-device-width: 769px)",
  });

  const parcelaValor = (x) => {
    if (x === "1") {
      return !payment ? cartTotal : (cartTotal / x).toFixed(2);
    } else {
      return !payment
        ? cartTotalParcelado
        : (cartTotalParcelado / x).toFixed(2);
    }
  };

  const handlePaymentMethodChange = useCallback(
    (metodo) => {
      setPaymentMethod(metodo);
      if (metodo === "gratuito") {
        const paymentMethodId = paymentMethodIds?.find(
          (paymentMethod) => paymentMethod?.code === "2"
        );
        setPaymentMethodId(paymentMethodId.id);
      } else if (metodo === credito) {
        const paymentMethodId = paymentMethodIds?.find(
          (paymentMethod) => paymentMethod?.code === "1"
        );
        setPaymentMethodId(paymentMethodId.id);
      } else {
        const paymentMethodId = paymentMethodIds?.find(
          (paymentMethod) => paymentMethod?.code === "0"
        );
        setPaymentMethodId(paymentMethodId.id);
      }
    },
    [credito, paymentMethodIds, setPaymentMethod, setPaymentMethodId]
  );

  function handleCheckboxMark(value) {
    if (value === "disabled") {
      setAcceptedTerms("enabled");
    } else {
      setAcceptedTerms("disabled");
    }
  }

  useEffect(() => {
    if (!!paymentMethodIds) {
      if (cartTotal === "0.00") handlePaymentMethodChange("gratuito");
      else handlePaymentMethodChange(boleto);
    }
  }, [paymentMethodIds, cartTotal, boleto, handlePaymentMethodChange]);

  if (!carrinho | !carrinho.length)
    return (
      <div className="pagamento-wrapper">
        <img id="pagamento-loading" src={LoadingBars} alt="" />
      </div>
    );

  return (
    <div className="mt-5">
      {!isNotMobileDevice && <PaymentCart />}
      <div
        className="compra-title-section-container"
        style={{ "--before-color": "var(--green-base)" }}
      >
        <h1 className="title-section mt-1 font-semibold text-lg sm:text-xl">
          Formas de Pagamento
        </h1>
        <hr />
      </div>
      <h4 className="font-semibold text-green-base">
        Selecione a modalidade de pagamento e confirme sua compra
      </h4>

      <div className="pagamento-wrapper">
        {isLoading === true ? (
          <img id="pagamento-loading" src={LoadingBars} alt="" />
        ) : (
          <>
            {cartTotal === "0.00" ? (
              <div className="pagamento-content">
                <form className="pagamento-form">
                  <div className="pagamento-content-btns">
                    <div
                      className={
                        paymentMethod === "gratuito"
                          ? "pagamento-btn-enabled"
                          : "pagamento-btn-disabled"
                      }
                      data-toggle="tab"
                      role="tablist"
                    >
                      <div className="filter-text">Gratuito</div>
                      <div className="checkbox-btn">
                        <label className="checkbox-label">
                          <input
                            type="radio"
                            name="forma_pagamento"
                            value="boleto"
                            onChange={() =>
                              handlePaymentMethodChange("gratuito")
                            }
                          />
                          <span className="checkmark"></span>
                          <img src={IconBoleto} alt="" />
                        </label>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            ) : (
              <>
                <div className="pagamento-content">
                  <form className="pagamento-form">
                    <div className="pagamento-content-btns">
                      <div
                        className={
                          paymentMethod === boleto
                            ? "pagamento-btn-enabled"
                            : "pagamento-btn-disabled"
                        }
                        data-toggle="tab"
                        role="tablist"
                      >
                        <div className="filter-text">Boleto Bancário</div>
                        <div className="checkbox-btn">
                          <label className="checkbox-label">
                            <input
                              type="radio"
                              name="forma_pagamento"
                              value="boleto"
                              onChange={() => handlePaymentMethodChange(boleto)}
                            />
                            <span className="checkmark"></span>
                            <img src={IconBoleto} alt="" />
                          </label>
                        </div>
                      </div>

                      <div
                        className={
                          "cartão-de-crédito " +
                          (paymentMethod === credito
                            ? "pagamento-btn-enabled"
                            : "pagamento-btn-disabled")
                        }
                        onChange={() => handlePaymentMethodChange(credito)}
                        data-toggle="tab"
                        role="tablist"
                      >
                        <div className="filter-text">Cartão de Crédito</div>
                        <div className="checkbox-btn">
                          <label className="checkbox-label">
                            <input
                              type="radio"
                              name="forma_pagamento"
                              value="cartão"
                            />
                            <span className="checkmark"></span>

                            <img src={IconCardMaster} alt="" />
                          </label>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="pagamento-content-bottom">
                  <div
                    className={paymentMethod === credito ? "block" : "hidden"}
                    id="link2"
                  >
                    {paymentMethod === credito ? <FormCartão /> : null}
                  </div>
                </div>
                <CuponContainer />
              </>
            )}
            <div className="pagamento-total">
              <div className="cart-orçamento">
                <div className="orçamento-total">
                  <div className="orçamento-total-content">
                    <div className="orçamento-subtotal">TOTAL</div>
                    <div className="orçamento-número">
                      R${" "}
                      {paymentMethod === boleto || paymentMethod === credito
                        ? formatPriceBR(cartTotal)
                        : formatPriceBR(cartTotalParcelado)}
                    </div>
                  </div>
                  <div className="cart-orçamento-detalhes">
                    <div
                      className={paymentMethod === boleto ? "block" : "hidden"}
                      id="link1"
                    >
                      À <span className="blue-bold">vista</span> no Boleto
                      Bancário{" "}
                    </div>
                    {!payment?.installments === true &&
                    paymentMethod === credito ? (
                      "Selecione o Parcelamento"
                    ) : (
                      <div
                        className={
                          paymentMethod === credito ? "block" : "hidden"
                        }
                        id="link2"
                      >
                        Parcelado em{" "}
                        <span className="blue-bold">
                          {!payment ? "1" : payment?.installments} x
                        </span>{" "}
                        de{" "}
                        <span className="blue-bold">
                          R${" "}
                          {formatPriceBR(parcelaValor(payment?.installments))}
                        </span>{" "}
                        no Crédito
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="form-container-termos">
              <div className="filter-option">
                <label className="container">
                  Aceitar{" "}
                  <a
                    className="blue-bold"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={termsContracts}
                  >
                    Termos de Uso{" "}
                  </a>
                  da plataforma.
                  <input
                    value={acceptedTerms}
                    checked={acceptedTerms === "enabled" ? true : false}
                    onChange={(e) => handleCheckboxMark(e.target.value)}
                    type="checkbox"
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
            </div>
            <button
              onClick={handleSubmitPayment}
              className="flex items-center justify-center gap-3 text-white bg-green-base p-3 font-semibold rounded-lg hover:bg-[var(--green-dark)]"
            >
              Finalizar Compra
              <span class="material-symbols-outlined">arrow_forward</span>
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default Payment;
