import { useContext, useState } from "react";
import { CartContext } from "../../contexts/CartContext";
import { ProductsContext } from "../../contexts/ProductsContext";
import { AccountContext } from "../../contexts/AccountContext";

import LoadingBars from "../../assets/Loading/loading-bars.svg";
import "./VinculateStudent.styles.scss";
import CardVinculo from "../CardVinculo/CardVinculo";
import NewVinculation from "./NewVinculation/NewVinculation";

const VinculateStudent = ({ nextStep }) => {
  const { alreadyStudent } = useContext(CartContext);
  const {
    studentList,
    removeStudentFromUser,
    handleChangeAddStudent,
    handleSubmitAddStudent,
    isLoading,
  } = useContext(AccountContext);
  const { selectedSubproduct, selectedProduct } = useContext(ProductsContext);

  const [openList, setOpenList] = useState(false);
  const [vinculateForm, setVinculateForm] = useState(false);

  const handleOpenList = (_event, toList) => {
    setOpenList(!!toList ? true : false);
    setVinculateForm(false);
  };

  return (
    <div className="mt-5 flex flex-col justify-center">
      {vinculateForm ? (
        <NewVinculation handleOpenList={(e) => handleOpenList(e, true)} />
      ) : (
        <>
          <h2 className="py-4 text-green-base font-semibold text-center text-base sm:text-2xl">
            {!openList ? (
              "Esse Produto é para quem?"
            ) : (
              <>
                Selecione ou adicione um aluno para
                <br />
                comprar o produto!
              </>
            )}
          </h2>
          <div className="d-flex justify-center w-full">
            {!openList && (
              <div className="card-container">
                <div
                  className="card-sou-estudante"
                  onClick={() => alreadyStudent(selectedSubproduct, nextStep)}
                >
                  <span class="material-symbols-outlined">person</span>
                  <div>
                    <h1>Sou Aluno</h1>
                    <p>Selecione essa opção caso a compra seja para você</p>
                  </div>
                </div>

                <div
                  className="card-sou-estudante"
                  onClick={() => setOpenList(true)}
                >
                  <span class="material-symbols-outlined">group</span>
                  <div>
                    <h1>Lista de Alunos</h1>
                    <p>
                      Selecione essa opção caso a compra seja para outra pessoa
                    </p>
                  </div>
                </div>
              </div>
            )}
            {openList && !!selectedProduct && (
              <>
                <div className="compra-title-section-container" style={{'--before-color': 'var(--yellow-base)'}}>
                  <h1 className="title-section font-semibold text-lg sm:text-xl">
                    Alunos Vinculados
                  </h1>
                  <hr />
                </div>
                <div className="student-card-list">
                  {studentList.length ? (
                    studentList.map((student) => (
                      <CardVinculo
                        key={student.id}
                        Student={student}
                        Product={selectedSubproduct}
                        onClickVinculate={true}
                        vinculateCallback={nextStep}
                        removeStudentFromUser={() =>
                          removeStudentFromUser(student.student.id)
                        }
                      />
                    ))
                  ) : (
                    <h1 className="my-8 text-center w-full text-lg font-semibold text-grey-dark">
                      Nenhum aluno vinculado no momento
                    </h1>
                  )}
                </div>
                <div className="flex flex-col gap-4">
                  <div className="compra-title-section-container" style={{'--before-color': 'var(--yellow-base)'}}>
                    <h1 className="title-section mt-1 font-semibold text-lg sm:text-xl">
                      Adicionar Novo Aluno
                    </h1>
                    <hr />
                  </div>
                  <div className="flex flex-col gap-2 justify-center items-center">
                    <div className="search-box flex flex-col justify-center items-center gap-2 w-full">
                      <h1 className="text-green-base text-center font-semibold">
                        Adicionar aluno por RA
                      </h1>
                      <form
                        className="search-form w-full md:w-[80%]"
                        onSubmit={handleSubmitAddStudent}
                      >
                        <div className="relative">
                          <div className="search-icon">
                            <span className="material-symbols-outlined">
                              person_search
                            </span>
                          </div>
                          <input
                            type="search"
                            id="search-ra"
                            className="search-field !pl-10"
                            placeholder="Digite o RA do Aluno"
                            required
                            onChange={({ target: { value } }) =>
                              handleChangeAddStudent(value)
                            }
                          />
                          <button
                            disabled={isLoading === true ? true : false}
                            type="submit"
                            className={
                              isLoading === true
                                ? `btn-pesquisar-loading`
                                : `btn-adicionar`
                            }
                          >
                            {isLoading === true ? (
                              <img src={LoadingBars} alt="" />
                            ) : (
                              "Adicionar"
                            )}
                          </button>
                        </div>
                      </form>
                    </div>
                    <h5 className="text-grey-dark text-center font-semibold">
                      ou
                    </h5>
                    <button
                      className="border shadow-md p-3 rounded-xl w-fit hover:bg-slate-100"
                      onClick={() => setVinculateForm(!vinculateForm)}
                    >
                      <div className="flex items-center gap-2">
                        <span class="material-symbols-outlined text-green-base text-5xl">
                          add_circle
                        </span>
                        <div className="flex flex-col items-start">
                          <h3 className="text-green-base text-center font-semibold">
                            Adicionar aluno sem RA
                          </h3>
                          <h5 className="text-grey-brand text-sm text-center font-medium">
                            Alunos ainda não matriculados na escola
                          </h5>
                        </div>
                      </div>
                    </button>
                    <button
                      onClick={handleOpenList}
                      className="self-start mt-5 flex items-center bg-yellow-base rounded-3xl text-white py-2 px-4 gap-1 font-semibold"
                    >
                      <span class="material-symbols-outlined font-semibold">
                        arrow_back
                      </span>
                      Voltar
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default VinculateStudent;
