import { useContext } from "react";
import { GlobalContext } from "../../contexts/GlobalContext";
import BagView from "./BagView/BagView";

const PaymentCart = () => {
  const { carrinho } = useContext(GlobalContext);

  return (
    <div className="bg-gray-100 cart-view h-fit rounded-lg shadow-md">
      <div className="bg-yellow-base text-grey-dark rounded-t-lg p-2 px-3 font-semibold text-lg">
        Carrinho
      </div>

      <div className="p-2 flex flex-col gap-3">
        {carrinho.map((bag, index) => (
          <BagView key={bag.studentId} index={index} bag={bag} />
        ))}
      </div>
    </div>
  );
};

export default PaymentCart;
